import { useContext, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { WebAppContext } from '../contexts/WebAppContexts.tsx'
import axios from 'axios'
import { API } from '../constants.tsx'
import store, { RootState } from '../store'
import { DataState, setAuthToken } from '../store/slices/data.ts'
import { useSelector } from 'react-redux'
// import useGeoLocation from "../hooks/useGeoLocation.tsx";

export const useAuthToken = () => {
    const { WebApp } = useContext(WebAppContext)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [searchParams, _] = useSearchParams()
    const data = useSelector<RootState>((state) => state.data) as DataState
    const mayBeAuthToken = searchParams.get('tg_auth_token')
    // const geo = useGeoLocation();
    const [tokenRequested, setTokenRequested] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {
        if (data.authToken === null) {
            if (mayBeAuthToken !== null) {
                store.dispatch(setAuthToken(mayBeAuthToken))
            } else {
                if (WebApp?.initData && !tokenRequested) {
                    setTokenRequested(true)
                    axios
                        .get(`${API}/api/auth/telegram/verify?${WebApp?.initData}`)
                        .then((resp) => {
                            if (resp.data !== null) {
                                store.dispatch(setAuthToken(resp.data))
                            } else {
                                setTokenRequested(false)
                            }
                        })
                        .catch(() => {
                            navigate('/error')
                        })
                }
            }
        }
    }, [WebApp?.initData])
    return data.authToken || mayBeAuthToken
}
