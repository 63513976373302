import robot from '../../assets/images/robot.png'
import styles from './UnderMaintenance.module.scss'
import { useContext } from 'react'
import { WebAppContext } from '../../contexts/WebAppContexts.tsx'

function UnderMaintenance() {
    const { WebApp } = useContext(WebAppContext)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars

    return (
        <div className={styles.soonPage}>
            <div className={styles.imgContainer}>
                <div>
                    <img className={styles.img} src={robot} alt='dormint' />
                </div>
            </div>
            <h2 className={styles.title}>Dormint Bot is under maintenance.</h2>
            <button
                className={styles.button}
                onClick={() => {
                    WebApp?.close()
                }}
            >
                OK
            </button>
        </div>
    )
}

export default UnderMaintenance
