import { useEffect, useState } from 'react'
import styles from './SpotQuestCard.module.scss'
import GreeenDoneIcon from '../../assets/icons/GreenDoneIcon'
import RedErrorIcon from '../../assets/icons/RedErrorIcon.tsx'
import { useSpotAd } from 'spot-ads-react'
import axios from 'axios'
import { API, encryptAdsToken } from '../../constants.tsx'
import { useAuthToken } from '../../hooks/useAuthToken.tsx'
import { v4 as uuidv4 } from 'uuid'
import { useNavigate } from 'react-router-dom'

const AdsGramLogo = ({ width, height }: { width: number; height: number }) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox='-2.4 -2.4 28.80 28.80'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            stroke='#FFFFFF'
        >
            <path
                d='M5 5H19C20.1046 5 21 5.89543 21 7V17C21 18.1046 20.1046 19 19 19H5C3.89543 19 3 18.1046 3 17V7C3 5.89543 3.89543 5 5 5Z'
                stroke='#FFFFFF'
                strokeWidth='1.416'
                strokeLinecap='round'
                strokeLinejoin='round'
            ></path>
            <path
                d='M7 15V11C7 9.89543 7.89543 9 9 9V9C10.1046 9 11 9.89543 11 11V15'
                stroke='#FFFFFF'
                strokeWidth='1.416'
                strokeLinecap='round'
                strokeLinejoin='round'
            ></path>
            <path
                d='M17 9V15'
                stroke='#FFFFFF'
                strokeWidth='1.416'
                strokeLinecap='round'
                strokeLinejoin='round'
            ></path>
            <path
                d='M17 12H15.5C13.5 12 13.5 15 15.5 15H17'
                stroke='#FFFFFF'
                strokeWidth='1.416'
                strokeLinecap='round'
                strokeLinejoin='round'
            ></path>
            <path
                d='M7 13H11'
                stroke='#FFFFFF'
                strokeWidth='1.416'
                strokeLinecap='round'
                strokeLinejoin='round'
            ></path>
        </svg>
    )
}

function SpotQuestCard() {
    const authToken = useAuthToken()
    const [adsToken, setAdsToken] = useState<string | null>(null)
    const [newCompletedQuest, setNewCompletedQuest] = useState(false)
    const [errorCompletedQuest, setErrorCompletedQuest] = useState(false)
    const [errorAdsQuest, setErrorAdsQuest] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const [animate, setAnimate] = useState<'open' | 'close'>('open')

    const navigate = useNavigate()

    const handleReward = () => {
        if (adsToken) {
            axios
                .post(`${API}/tg/ads/submit`, {
                    auth_token: authToken,
                    ads_token: encryptAdsToken(adsToken),
                })
                .then((resp) => {
                    if (resp.data.status === 'ok') {
                        setNewCompletedQuest(true)
                    } else {
                        setErrorCompletedQuest(true)
                    }
                    setAdsToken(null)
                })
                .catch(() => {
                    navigate('/error')
                })
        } else {
            setErrorCompletedQuest(true)
        }
    }

    const handleError = () => {
        setErrorAdsQuest(true)
    }

    const spotAd = useSpotAd()

    useEffect(() => {
        if (adsToken) {
            spotAd.showAd({
                onSuccess: handleReward,
                onError: handleError,
            })
        }
    }, [adsToken, spotAd])

    useEffect(() => {
        if (newCompletedQuest) {
            setShowAlert(true)
            setTimeout(() => setAnimate('close'), 2000)
            setTimeout(() => {
                setShowAlert(false)
                setNewCompletedQuest(false)
            }, 2500)
        }
    }, [newCompletedQuest])

    useEffect(() => {
        if (errorCompletedQuest || errorAdsQuest) {
            setShowAlert(true)
            setTimeout(() => setAnimate('close'), 3500)
            setTimeout(() => {
                setShowAlert(false)
                setErrorCompletedQuest(false)
                setErrorAdsQuest(false)
            }, 3500)
        }
    }, [errorCompletedQuest, errorAdsQuest])

    return (
        <>
            {showAlert && (
                <div className={styles[`${animate === 'open' ? 'alert_open' : 'alert_close'}`]}>
                    {newCompletedQuest ? <GreeenDoneIcon /> : <RedErrorIcon />}
                    {newCompletedQuest
                        ? 'You received +50 Sleepcoins!'
                        : 'Something went wrong, please try again!'}
                </div>
            )}
            <div
                className={styles.questDiv}
                onClick={() => {
                    axios
                        .post(`${API}/tg/ads/start`, {
                            auth_token: authToken,
                            uuid: uuidv4(),
                        })
                        .then((resp) => {
                            if (resp.data.status === 'ok') {
                                setAdsToken(resp.data.adsToken)
                            } else {
                                setErrorAdsQuest(true)
                            }
                        })
                        .catch(() => {
                            navigate('/error')
                        })
                }}
                style={{ cursor: 'pointer' }}
            >
                <div className={styles.questInfo}>
                    <div className={styles.imgContainer}>
                        <AdsGramLogo width={20} height={20} />
                    </div>
                    <div className={styles.textDiv}>Watch Ad to Earn SleepCoins</div>
                </div>
                <div className={styles.questInfo}>
                    <span style={{ whiteSpace: 'nowrap', color: '#fff' }}>+50 SC</span>
                    <div className={styles.circle} />
                </div>
            </div>
        </>
    )
}

export default SpotQuestCard
