function LikeIcon() {
    return (
        <svg width='18' height='18' viewBox='0 0 18 18' fill='none'>
            <path
                d='M16.4531 5.63342C16.2947 5.45393 16.1 5.31019 15.8818 5.21176C15.6635 5.11332 15.4269 5.06244 15.1875 5.06248H11.25V3.93748C11.25 3.19156 10.9537 2.47619 10.4262 1.94875C9.89879 1.4213 9.18342 1.12498 8.4375 1.12498C8.333 1.12491 8.23055 1.15395 8.14162 1.20884C8.0527 1.26373 7.98083 1.34231 7.93406 1.43577L5.27766 6.74998H2.25C1.95163 6.74998 1.66548 6.86851 1.4545 7.07949C1.24353 7.29047 1.125 7.57661 1.125 7.87498V14.0625C1.125 14.3609 1.24353 14.647 1.4545 14.858C1.66548 15.069 1.95163 15.1875 2.25 15.1875H14.3438C14.7549 15.1876 15.1519 15.0377 15.4603 14.7658C15.7687 14.4939 15.9672 14.1188 16.0186 13.7109L16.8623 6.96092C16.8923 6.72328 16.8712 6.482 16.8007 6.25311C16.7301 6.02422 16.6116 5.81298 16.4531 5.63342ZM2.25 7.87498H5.0625V14.0625H2.25V7.87498ZM15.7458 6.8203L14.902 13.5703C14.8849 13.7063 14.8187 13.8313 14.7159 13.9219C14.6131 14.0125 14.4808 14.0625 14.3438 14.0625H6.1875V7.44537L8.76867 2.28233C9.15124 2.3589 9.49545 2.56566 9.74274 2.86743C9.99003 3.1692 10.1251 3.54733 10.125 3.93748V5.62498C10.125 5.77417 10.1843 5.91724 10.2898 6.02273C10.3952 6.12822 10.5383 6.18748 10.6875 6.18748H15.1875C15.2673 6.18746 15.3462 6.20442 15.419 6.23724C15.4918 6.27006 15.5567 6.318 15.6095 6.37785C15.6623 6.43771 15.7018 6.50812 15.7253 6.5844C15.7488 6.66069 15.7558 6.7411 15.7458 6.8203Z'
                fill='white'
                fill-opacity='0.7'
            />
        </svg>
    )
}

export default LikeIcon
