import { useEffect, useRef, useState } from 'react'
import ChatHeader from '../ChatHeader/ChatHeader'
import ChatInput from '../ChatInput/ChatInput'
import ChatMessageBoard from '../ChatMessageBoard/ChatMessageBoard'
import styles from './Chat.module.scss'
import { API, MessageInteface } from '../../constants'
import axios from 'axios'
import { useAuthToken } from '../../hooks/useAuthToken.tsx'

function Chat() {
    const [messages, setMessages] = useState<Array<MessageInteface>>([])
    const messageBoardRef = useRef<HTMLDivElement | null>(null)
    const [blockingLoading, setBlockingLoading] = useState<boolean>(true)

    const scrollToBottom = () => {
        messageBoardRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
    const authToken = useAuthToken()

    const fetchChatMessages = () => {
        axios
            .post(`${API}/agent/messages`, {
                auth_token: authToken,
            })
            .then((resp) => {
                if (resp.data.status === 'ok') {
                    setMessages((currentMessages: Array<MessageInteface>) => {
                        if (resp.data.messages.length > currentMessages.length) {
                            scrollToBottom()
                        }
                        return resp.data.messages.map((msg: any) => ({
                            sender: msg.by,
                            text: msg.text,
                        }))
                    })
                }
            })
    }

    useEffect(() => {
        axios
            .post(`${API}/agent/opened`, {
                auth_token: authToken,
            })
            .then((resp) => {
                if (resp.data.status === 'ok') {
                    setBlockingLoading(false)
                }
            })
    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            fetchChatMessages()
        }, 5000)

        return () => clearInterval(interval)
    }, [])

    return (
        <div className={styles.chatContainer}>
            <ChatHeader />
            <ChatMessageBoard messages={messages} messageBoardRef={messageBoardRef} />
            <ChatInput
                setMessages={setMessages}
                blockingLoading={blockingLoading}
                setBlockingLoading={setBlockingLoading}
            />
        </div>
    )
}

export default Chat
