function Sleepcoin({ width, height, color }: { width: string; height: string; color: string }) {
    return (
        <svg width={width} height={height} viewBox='0 0 20 22' fill='none'>
            <path
                d='M16.25 17.0938C11.5039 17.0938 7.65625 13.4024 7.65625 8.65625C7.65625 5.76195 9.09164 3.36398 11.2844 1.80672C11.1168 1.79773 10.9511 1.78125 10.7812 1.78125C5.60355 1.78125 1.40625 5.8223 1.40625 11C1.40625 16.1777 5.60355 20.2188 10.7812 20.2188C13.6334 20.2188 16.1832 18.9408 17.9027 16.9312C17.3675 17.0356 16.8157 17.0938 16.25 17.0938Z'
                stroke={color}
                strokeWidth='1.875'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M12.3438 8.4375H15.4688L12.3438 11.5625H15.4688M15.4688 2.1875H18.5938L15.4688 5.3125H18.5938'
                stroke={color}
                strokeWidth='1.875'
                strokeMiterlimit='10'
                strokeLinecap='square'
                strokeLinejoin='round'
            />
        </svg>
    )
}

export default Sleepcoin
