function Download({ width, height, color }: { width: string; height: string; color?: string }) {
    return (
        <svg width={width} height={height} viewBox='0 0 24 24' fill='none'>
            <path
                d='M3 15V19C3 20.1 3.9 21 5 21H19C19.5304 21 20.0391 20.7893 20.4142 20.4142C20.7893 20.0391 21 19.5304 21 19V15M17 9L12 14L7 9M12 12.8V2.5'
                stroke={color ? color : '#7883FE'}
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}

export default Download
