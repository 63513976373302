import PageHeader from '../../components/PageHeader'
import styles from './VPN.module.scss'
import waves from '../../assets/images/big_waves.png'
import { useContext, useEffect, useState } from 'react'
import VPNLock from '../../assets/images/vpn_lock.png'
import LoginIcon from '../../assets/icons/LoginIcon'
import PasswordIcon from '../../assets/icons/PasswordIcon'
import InputCopyIcon from '../../assets/icons/InputCopyIcon'
import IOSIcon from '../../assets/icons/IOSIcon'
import AndroidIcon from '../../assets/icons/AndroidIcon'
import AppleIcon from '../../assets/icons/AppleIcon'
import WindowsIcon from '../../assets/icons/WindowsIcon'
import { WebAppContext } from '../../contexts/WebAppContexts.tsx'
import { API, VPN_URLS } from '../../constants.tsx'
import { useAuthToken } from '../../hooks/useAuthToken.tsx'
import axios from 'axios'
import Subscription from '../Subscription'
import Loader from '../../components/Loader'
import { useNavigate } from 'react-router-dom'

function VPN() {
    const [login, setLogin] = useState('')
    const [password, setPassword] = useState('')
    const [expiration, setExpiration] = useState('')
    const [showCopyAlert, setShowCopyAlert] = useState(false)
    const [animateCopyAlert, setAnimateCopyAlert] = useState(true)

    const [isActive, setIsActive] = useState<boolean | null>(null)
    const [vpnStatusRequested, setVpnStatusRequested] = useState(false)

    const { WebApp } = useContext(WebAppContext)
    const authToken = useAuthToken()

    function handleShowCopyAlert() {
        setAnimateCopyAlert(true)
        setShowCopyAlert(true)
        setTimeout(() => {
            setAnimateCopyAlert(false)
        }, 1500)
        setTimeout(() => {
            setShowCopyAlert(false)
        }, 2000)
    }

    const navigate = useNavigate()

    useEffect(() => {
        if (!vpnStatusRequested && isActive === null) {
            setVpnStatusRequested(true)
            axios
                .post(`${API}/vpn/status`, {
                    auth_token: authToken,
                })
                .then((resp) => {
                    setLogin(resp.data.username)
                    setPassword(resp.data.password)
                    setIsActive(resp.data.isActive)
                    setExpiration(resp.data.expiration)
                })
                .catch(() => {
                    navigate('/error')
                })
        }
    }, [])

    return isActive === null || authToken === null ? (
        <Loader />
    ) : !isActive ? (
        <Subscription />
    ) : (
        <>
            {showCopyAlert && (
                <div className={styles[`${animateCopyAlert ? 'alert_open' : 'alert_close'}`]}>
                    Copied !
                </div>
            )}
            <div className={styles.vpnPage}>
                <PageHeader />
                <img
                    src={waves}
                    alt='bg'
                    style={{
                        width: '100vw',
                        position: 'absolute',
                        top: 0,
                        zIndex: -1,
                    }}
                />
                <div className={styles.content}>
                    <img src={VPNLock} alt='vpn_page' className={styles.vpnImage} />
                    <h2 className={styles.vpnTitle}>Get a VPN</h2>
                    <div className={styles.inputsContainer}>
                        <div className={styles.inputBlock}>
                            <div>
                                <LoginIcon />
                                <input type='text' placeholder='Login' value={login} />
                                <span
                                    onClick={() => {
                                        handleShowCopyAlert()
                                        navigator.clipboard.writeText(login)
                                    }}
                                >
                                    <InputCopyIcon />
                                </span>
                            </div>
                        </div>
                        <div className={styles.inputBlock}>
                            <div>
                                <PasswordIcon />
                                <input type='password' placeholder='Password' value={password} />
                                <span
                                    onClick={() => {
                                        handleShowCopyAlert()
                                        navigator.clipboard.writeText(password)
                                    }}
                                >
                                    <InputCopyIcon />
                                </span>
                            </div>
                        </div>
                    </div>
                    <p className={styles.paymentTitle}>Next payment on {expiration}</p>
                    <span className={styles.subInfoTitle}>Choose a platform</span>
                    <div className={styles.buttonsContainer}>
                        <button
                            className={styles.platformButton}
                            onClick={() => {
                                WebApp?.openLink(VPN_URLS.iOS, { try_instant_view: true })
                            }}
                        >
                            <IOSIcon />
                            <span>IOS</span>
                        </button>
                        <button
                            className={styles.platformButton}
                            onClick={() => {
                                WebApp?.openLink(VPN_URLS.Android, { try_instant_view: true })
                            }}
                        >
                            <AndroidIcon />
                            <span>Android</span>
                        </button>
                        <button
                            className={styles.platformButton}
                            onClick={() => {
                                WebApp?.openLink(VPN_URLS.MacOS, { try_instant_view: true })
                            }}
                        >
                            <AppleIcon />
                            <span>Mac OS</span>
                        </button>
                        <button
                            className={styles.platformButton}
                            onClick={() => {
                                WebApp?.openLink(VPN_URLS.Windows, { try_instant_view: true })
                            }}
                        >
                            <WindowsIcon />
                            <span>Windows</span>
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VPN
