import PageHeader from '../../components/PageHeader'
import FSLConnectImg from '../../assets/images/fsl_connect.png'
import styles from './FSLConnect.module.scss'
import waves from '../../assets/images/big_waves.png'
import FSLAuthorization from 'fsl-authorization'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { API } from '../../constants.tsx'
import { useAuthToken } from '../../hooks/useAuthToken.tsx'
import { useWebApp } from '../../hooks/useWebApp.tsx'
import RedErrorIcon from '../../assets/icons/RedErrorIcon.tsx'
import UserIcon from "../../assets/icons/UserIcon.tsx";
import IDIcon from "../../assets/icons/IDIcon.tsx";
import FSLWalletIcon from "../../assets/icons/FSLWalletIcon.tsx";


interface FSLWalletAddr {
    solana: string,
    evm: string,
}


interface FSLUserData {
    profileImage: string
    email: string
    fslUid: string
    walletAddr: FSLWalletAddr
}


function FSLAvatar({profileImage}: {profileImage: string}) {
    if (profileImage === "1") {
        return <img className={styles.userImg} src={`https://id.fsl.com/assets/users/${profileImage}.png`}/>
    }
    return <img className={styles.userImg} src={FSLConnectImg}/>
}


function FSLConnect() {
    const [fslWalletConnected, setFslWalletConnected] = useState<boolean | null>(null)
    const [userInfo, setUserInfo] = useState<FSLUserData | null>(null)

    const authToken = useAuthToken()
    const navigate = useNavigate()
    const webApp = useWebApp()
    const tgId = webApp?.initDataUnsafe?.user?.id || -1
    const [error, setError] = useState(false)
    const [animate, setAnimate] = useState<'open' | 'close'>('open')

    useEffect(() => {
        if (!authToken) {
            return
        }
        if (fslWalletConnected === null) {
            axios
                .post(`${API}/fsl/wallet/status`, {
                    auth_token: authToken,
                })
                .then((resp) => {
                    setFslWalletConnected(resp.data.fslWalletConnected)
                    setUserInfo(resp.data.fslAccountData)
                })
                .catch(() => {
                    navigate('/error')
                })
        }
    }, [authToken])

    useEffect(() => {
        if (error) {
            setTimeout(() => {
                setAnimate('close')
            }, 2000)
            setTimeout(() => {
                setError(false)
            }, 2000)
        }
    }, [error])

    return (
        <div style={{ position: 'relative' }}>
            <PageHeader />
            {error && (
                <div
                    className={styles[`${animate === 'open' ? 'alert_open' : 'alert_close'}`]}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                    }}
                >
                    <RedErrorIcon />
                    Now we can add only 1 wallet
                </div>
            )}
            <img
                src={waves}
                alt='bg'
                style={{
                    width: '100vw',
                    position: 'absolute',
                    top: 0,
                    zIndex: -1,
                }}
            />
            <div className={styles.content}>
                {!userInfo && <img src={FSLConnectImg} alt='fsl_connect' className={styles.fslImg} />}
                {userInfo && (
                    <div className={styles.infoContainer}>
                        <div className={styles.imgContainer}>
                            <FSLAvatar profileImage={userInfo.profileImage} />
                        </div>
                        <div className={styles.blockContainer}>
                            <div className={styles.infoBlock}>
                                <UserIcon />
                                <span className={styles.infoText}>{userInfo.email}</span>
                            </div>
                        </div>
                        <div className={styles.blockContainer}>
                            <div className={styles.infoBlock}>
                                <IDIcon />
                                <span className={styles.infoText}>{userInfo.fslUid}</span>
                            </div>
                        </div>
                        <div className={styles.blockContainer}>
                            <div className={styles.infoBlock}>
                                <FSLWalletIcon />
                                <span className={styles.infoText}>{userInfo.walletAddr.evm.slice(0, 4) + '...' + userInfo.walletAddr.evm.slice(userInfo.walletAddr.evm.length - 8, userInfo.walletAddr.evm.length)}</span>
                            </div>
                        </div>
                        <button
                            className={styles.disconnectBtn}
                            onClick={() => {
                                if (!authToken || tgId === -1) {
                                    setError(true)
                                } else {
                                    axios
                                        .post(`${API}/fsl/wallet/disconnect`, {
                                            auth_token: authToken,
                                        })
                                        .then(() => {
                                            setFslWalletConnected(false)
                                            setUserInfo(null)
                                        })
                                        .catch(() => {
                                            navigate('/error')
                                        })
                                }
                            }}
                        >
                            Disconnect FSL ID
                        </button>
                    </div>
                )}
                {!fslWalletConnected && <button
                    className={styles.connectBtn}
                    onClick={() => {
                        if (!authToken || tgId === -1) {
                            setError(true)
                        } else {
                            const fslAuthorization = FSLAuthorization.init({
                                responseType: 'code', // 'code' or 'token'
                                appKey: 'dfuy!6eE2z',
                                redirectUri: 'https://api-new.dormint.io/api/auth/fsl/verify', // https://xxx.xxx.com
                                scope: 'basic,wallet', // Grant Scope
                                state: tgId.toString(),
                                isApp: true,
                                usePopup: false, // Popup a window instead of jump to
                            })
                            console.log(fslAuthorization)
                            fslAuthorization.signIn().then((code: any) => {
                                if (code) {
                                    console.log('FSL: ', code)
                                }
                            })
                        }
                    }}
                >
                    Connect FSL ID
                </button>}
            </div>
        </div>
    )
}

export default FSLConnect
