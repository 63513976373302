import styles from './DormintWalletCard.module.scss'
import TonWalletIcon from '../../assets/icons/TonWalletIcon.tsx'
import Spinner from '../../assets/icons/Spinner.tsx'
import { styled } from '@mui/material/styles'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        boxShadow: theme.shadows[1],
        color: '#2D2F54',
        textAlign: 'center',
        fontSize: 10,
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
    },
}))

const DormintWalletCard = ({
    canWithdraw,
    whyCantWithdraw,
    tonBalance,
}: {
    canWithdraw: boolean
    whyCantWithdraw: string
    tonBalance: number | null
}) => {
    return (
        <div className={styles.walletCard}>
            <div className={styles.walletCardInfo}>
                <TonWalletIcon />
                <div className={styles.walletCardText}>
                    <span>Dormint Wallet</span>
                    <div>Internal address</div>
                    <div style={{ display: 'inline-flex' }}>
                        {tonBalance !== null ? (
                            <>{tonBalance}</>
                        ) : (
                            <Spinner width={'16px'} height={'16px'} />
                        )}{' '}
                        TON
                    </div>
                </div>
            </div>
            <LightTooltip title={whyCantWithdraw}>
                <button
                    onClick={() => {}}
                    className={styles.farmingButton}
                    style={{
                        color: canWithdraw ? '#fff' : 'rgba(255, 255, 255, 0.1)',
                        cursor: canWithdraw ? 'pointer' : 'help',
                        background: canWithdraw
                            ? 'linear-gradient(180deg, #6B7AFF 0%, #4940F2 100%)'
                            : '#2D2F54',
                    }}
                >
                    <span>Withdraw</span>
                </button>
            </LightTooltip>
        </div>
    )
}

export default DormintWalletCard
