function FSLWalletIcon() {
    return (
        <svg width='20' height='20' viewBox='0 0 20 20' fill='none'>
            <path
                d='M4.7048 4.05179C3.90735 4.22067 3.28178 4.8174 3.05072 5.63367C3.00282 5.80537 3 6.04743 3 10.5003C3 14.9532 3.00282 15.1953 3.05072 15.367C3.27333 16.1495 3.83408 16.7096 4.62026 16.9348C4.79497 16.9855 5.01194 16.9883 9.3796 16.9967C14.4264 17.0052 14.2376 17.0108 14.6687 16.8307C15.1759 16.6196 15.6211 16.1748 15.8325 15.671C16.0072 15.246 16.0072 15.2741 15.9959 12.1864C15.9874 9.59118 15.9846 9.37726 15.9367 9.21963C15.6888 8.39773 15.0745 7.82071 14.2714 7.65464C14.0319 7.60398 13.798 7.60116 9.70083 7.60116C5.58396 7.60116 5.37826 7.59835 5.21764 7.5505C4.82878 7.42947 4.56109 7.12266 4.47937 6.71171C4.43992 6.50623 4.47373 6.27824 4.57799 6.06151C4.6738 5.86729 4.93868 5.62522 5.1472 5.54078L5.31063 5.47604L9.67829 5.46197C14.6067 5.44508 14.1389 5.47041 14.3672 5.22272C14.6433 4.92435 14.6152 4.44585 14.3052 4.18127C14.0798 3.98705 14.3869 3.99831 9.4275 4.00112C5.22328 4.00112 4.93022 4.00675 4.7048 4.05179ZM13.0033 11.2744C13.3161 11.356 13.5641 11.5671 13.7134 11.8796C13.7811 12.0147 13.7895 12.0681 13.7895 12.3018C13.7895 12.5354 13.7811 12.5889 13.7134 12.724C13.2457 13.6894 11.9016 13.5177 11.6902 12.4622C11.6085 12.0541 11.8649 11.5587 12.2594 11.356C12.513 11.2294 12.7328 11.204 13.0033 11.2744Z'
                fill='white'
            />
        </svg>
    )
}

export default FSLWalletIcon
