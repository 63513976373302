import styles from './FAQ.module.scss'
import DownArrow from '../../assets/icons/DownArrow'
import { useState } from 'react'
import AnimateHeight from 'react-animate-height'
import PageHeader from '../../components/PageHeader'

function FAQ() {
    const [height1, setHeight1] = useState<'auto' | number>(0)
    const [height2, setHeight2] = useState<'auto' | number>(0)
    const [height4, setHeight4] = useState<'auto' | number>(0)
    const [height5, setHeight5] = useState<'auto' | number>(0)
    const [height6, setHeight6] = useState<'auto' | number>(0)
    const [height7, setHeight7] = useState<'auto' | number>(0)

    return (
        <div className={styles.faqPage}>
            <PageHeader />
            <div className={styles.questions}>
                <h2>FAQ</h2>
                <div
                    className={styles.questionDiv}
                    aria-expanded={height1 !== 0}
                    aria-controls='answer_1'
                    onClick={() => setHeight1(height1 === 0 ? 'auto' : 0)}
                >
                    <div style={{ cursor: 'pointer' }}>
                        <h3>What's Dormint?</h3>
                        <span
                            style={{
                                transform: height1 === 'auto' ? 'rotate(180deg)' : 'rotate(0deg)',
                                transition: '0.5s',
                            }}
                        >
                            <DownArrow width='18px' height='18px' />
                        </span>
                    </div>
                    <AnimateHeight
                        id='answer_1'
                        duration={500}
                        height={height1}
                        style={{
                            marginTop: height1 === 0 ? '-10px' : '0',
                            transition: '0.5s',
                        }}
                    >
                        <p className={styles.text}>
                            <b>Dormint</b> the first Web3 AI-powered sleep-tracking app that allows
                            you to generate additional income by improving your sleeping cycle,
                            simultaneously utilizing sleeping users' devices to create a DePIN and
                            provide them with additional services
                        </p>
                    </AnimateHeight>
                </div>
                <div
                    className={styles.questionDiv}
                    aria-expanded={height2 !== 0}
                    aria-controls='answer_2'
                    onClick={() => setHeight2(height2 === 0 ? 'auto' : 0)}
                >
                    <div style={{ cursor: 'pointer' }}>
                        <h3>What are Sleepcoins?</h3>
                        <span
                            style={{
                                transform: height2 === 'auto' ? 'rotate(180deg)' : 'rotate(0deg)',
                                transition: '0.5s',
                            }}
                        >
                            <DownArrow width='18px' height='18px' />
                        </span>
                    </div>
                    <AnimateHeight
                        id='answer_2'
                        duration={500}
                        height={height2}
                        style={{
                            marginTop: height2 === 0 ? '-10px' : '0',
                            transition: '0.5s',
                        }}
                    >
                        <p className={styles.text}>
                            Sleepcoins are Dormint's pre-TGE unified rewards system for all
                            community related activities. You can farm Sleepcoins in this web app,
                            or get them for other activities. Upon completion of the TGE, Dormint
                            will convert all outstanding Sleepcoins into $DORM
                        </p>
                    </AnimateHeight>
                </div>
                <div
                    className={styles.questionDiv}
                    aria-expanded={height4 !== 0}
                    aria-controls='answer_4'
                    onClick={() => setHeight4(height4 === 0 ? 'auto' : 0)}
                >
                    <div style={{ cursor: 'pointer' }}>
                        <h3>When launch?</h3>
                        <span
                            style={{
                                transform: height4 === 'auto' ? 'rotate(180deg)' : 'rotate(0deg)',
                                transition: '0.5s',
                            }}
                        >
                            <DownArrow width='18px' height='18px' />
                        </span>
                    </div>
                    <AnimateHeight
                        id='answer_4'
                        duration={500}
                        height={height4}
                        style={{
                            marginTop: height4 === 0 ? '-10px' : '0',
                            transition: '0.5s',
                        }}
                    >
                        <p className={styles.text}>
                            We've already launched iOS Closed Beta with AI integration - you may
                            fill an{' '}
                            <a
                                href='https://docs.google.com/forms/d/e/1FAIpQLSc-g1mUBVm8vn8fSGal7xJy5L-2Jr5tklU1nGeUhSEEZDAMGw/viewform'
                                target='_blank'
                            >
                                application form
                            </a>{' '}
                            to take part in Open Beta once its live, or get an invite to the Closed
                            Beta via Dormint community activities!
                        </p>
                    </AnimateHeight>
                </div>
                <div
                    className={styles.questionDiv}
                    aria-expanded={height5 !== 0}
                    aria-controls='answer_5'
                    onClick={() => setHeight5(height5 === 0 ? 'auto' : 0)}
                >
                    <div style={{ cursor: 'pointer' }}>
                        <h3>When $DORM listing?</h3>
                        <span
                            style={{
                                transform: height5 === 'auto' ? 'rotate(180deg)' : 'rotate(0deg)',
                                transition: '0.5s',
                            }}
                        >
                            <DownArrow width='18px' height='18px' />
                        </span>
                    </div>
                    <AnimateHeight
                        id='answer_5'
                        duration={500}
                        height={height5}
                        style={{
                            marginTop: height5 === 0 ? '-10px' : '0',
                            transition: '0.5s',
                        }}
                    >
                        <p className={styles.text}>
                            TGE Target Q4 24' - Q1 25'{' '}
                            <a
                                href='https://twitter.com/Dormint_io/status/1750830155373679050?t=R6aVgGeZXgiDHk_wSt_J0Q&s=35'
                                target='_blank'
                            >
                                DAO Maker
                            </a>{' '}
                            and{' '}
                            <a
                                href='https://twitter.com/GameFi_Official/status/1765703666051531139?t=jIkU2DeDNcYgSSCd0fI8qA&s=35'
                                target='_blank'
                            >
                                GameFi
                            </a>{' '}
                            IDO confirmed. Stay tuned!
                        </p>
                    </AnimateHeight>
                </div>
                <div
                    className={styles.questionDiv}
                    aria-expanded={height6 !== 0}
                    aria-controls='answer_6'
                    onClick={() => setHeight6(height6 === 0 ? 'auto' : 0)}
                >
                    <div style={{ cursor: 'pointer' }}>
                        <h3>What partnerships do you have?</h3>
                        <span
                            style={{
                                transform: height6 === 'auto' ? 'rotate(180deg)' : 'rotate(0deg)',
                                transition: '0.5s',
                            }}
                        >
                            <DownArrow width='18px' height='18px' />
                        </span>
                    </div>
                    <AnimateHeight
                        id='answer_6'
                        duration={500}
                        height={height6}
                        style={{
                            marginTop: height6 === 0 ? '-10px' : '0',
                            transition: '0.5s',
                        }}
                    >
                        <p className={styles.text}>
                            <b>IDO</b>: DAO Maker; GameFi;
                            <br />
                            <br />
                            <b>Strategic</b>: Polygon;
                            <br />
                            <br />
                            <b>Acceleration and integration</b>:
                            <br />
                            &emsp;- Cointelegraph;
                            <br />
                            &emsp;- Tonstarter/NOTcoin (Telegram integration, TON Ecosystem
                            support);
                            <br />
                            <br />
                            <b>Others</b>:
                            <br />
                            &emsp;- Gotbit (Market making);
                            <br />
                            &emsp;- Magic Eden Launchpad, MOOAR (NFT distribution);
                            <br />
                            &emsp;- Chainlink (NFT rareness verification);
                        </p>
                    </AnimateHeight>
                </div>
                <div
                    className={styles.questionDiv}
                    aria-expanded={height7 !== 0}
                    aria-controls='answer_7'
                    onClick={() => setHeight7(height7 === 0 ? 'auto' : 0)}
                >
                    <div style={{ cursor: 'pointer' }}>
                        <h3>Socials?</h3>
                        <span
                            style={{
                                transform: height7 === 'auto' ? 'rotate(180deg)' : 'rotate(0deg)',
                                transition: '0.5s',
                            }}
                        >
                            <DownArrow width='18px' height='18px' />
                        </span>
                    </div>
                    <AnimateHeight
                        id='answer_7'
                        duration={500}
                        height={height7}
                        style={{
                            marginTop: height7 === 0 ? '-10px' : '0',
                            transition: '0.5s',
                        }}
                    >
                        <p className={styles.text}>
                            <a href='https://dormint.io/' target='_blank'>
                                Website
                            </a>{' '}
                            -{' '}
                            <a href='https://twitter.com/Dormint_io' target='_blank'>
                                Twitter
                            </a>{' '}
                            -{' '}
                            <a href='https://t.me/DormintAnnouncements' target='_blank'>
                                Telegram
                            </a>{' '}
                            -{' '}
                            <a href='https://discord.gg/dormint' target='_blank'>
                                Discord
                            </a>
                        </p>
                    </AnimateHeight>
                </div>
            </div>
        </div>
    )
}

export default FAQ
