function LeadersIcon({ isActive }: { isActive: boolean }) {
    return isActive ? (
        <svg
            width='28'
            height='25'
            viewBox='0 0 28 25'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <g filter='url(#filter0_d_8088_188)'>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M8.83844 6.55555C7.5096 6.80937 6.47049 7.82913 6.10679 9.2363C6.04439 9.47773 6.02997 9.6656 6.01627 10.4155L6 11.3075H8.00186H10.0037V8.90376V6.5L9.54878 6.50338C9.29854 6.50526 8.97891 6.52873 8.83844 6.55555ZM11.1455 8.90376V11.3075H11.7513H12.3571L12.9961 10.4688C13.6654 9.59037 13.7346 9.52528 14 9.52528C14.2654 9.52528 14.3346 9.59037 15.0039 10.4688L15.6429 11.3075H16.2487H16.8545V8.90376V6.5H14H11.1455V8.90376ZM17.9963 8.90376V11.3075H19.9981H22L21.9837 10.4155C21.97 9.6656 21.9556 9.47773 21.8932 9.2363C21.7174 8.55589 21.4327 8.04009 20.9625 7.5498C20.2742 6.83217 19.4697 6.5 18.4198 6.5H17.9963V8.90376ZM6.01516 15.0728L6.02526 17.6362L6.14718 17.8728C6.28259 18.1357 6.39371 18.2489 6.66371 18.3988L6.84593 18.5H14H21.1541L21.3402 18.3967C21.5766 18.2656 21.752 18.0809 21.8766 17.8321L21.9747 17.6362L21.9848 15.0728L21.9949 12.5094H18.819H15.6429L15.0039 13.3482C14.3414 14.2177 14.2429 14.3122 14 14.3122C13.7571 14.3122 13.6586 14.2177 12.9961 13.3482L12.3571 12.5094H9.18105H6.00507L6.01516 15.0728Z'
                    fill='#FFDE7D'
                />
            </g>
            <defs>
                <filter
                    id='filter0_d_8088_188'
                    x='0'
                    y='0.5'
                    width='28'
                    height='24'
                    filterUnits='userSpaceOnUse'
                    color-interpolation-filters='sRGB'
                >
                    <feFlood flood-opacity='0' result='BackgroundImageFix' />
                    <feColorMatrix
                        in='SourceAlpha'
                        type='matrix'
                        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                        result='hardAlpha'
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation='3' />
                    <feComposite in2='hardAlpha' operator='out' />
                    <feColorMatrix
                        type='matrix'
                        values='0 0 0 0 1 0 0 0 0 0.871729 0 0 0 0 0.491667 0 0 0 0.5 0'
                    />
                    <feBlend
                        mode='normal'
                        in2='BackgroundImageFix'
                        result='effect1_dropShadow_8088_188'
                    />
                    <feBlend
                        mode='normal'
                        in='SourceGraphic'
                        in2='effect1_dropShadow_8088_188'
                        result='shape'
                    />
                </filter>
            </defs>
        </svg>
    ) : (
        <svg
            width='17'
            height='18'
            viewBox='0 0 17 18'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <g opacity='0.5'>
                <path
                    d='M5.33333 10.211H3.16667C2.52333 10.211 2 10.7595 2 11.4338V15.2771C2 15.9515 2.52333 16.5 3.16667 16.5H5.33333V10.211ZM9.5 8.46404H7.5C6.85667 8.46404 6.33333 9.01258 6.33333 9.6869V16.5H10.6667V9.6869C10.6667 9.01258 10.1433 8.46404 9.5 8.46404ZM13.8333 11.9579H11.6667V16.5H13.8333C14.4767 16.5 15 15.9515 15 15.2771V13.1808C15 12.5065 14.4767 11.9579 13.8333 11.9579ZM11.3893 3.676C11.3605 3.58301 11.3075 3.5003 11.2362 3.43721C11.165 3.37412 11.0783 3.33315 10.986 3.31892L9.578 3.1051L8.94867 1.76833C8.78067 1.41056 8.22 1.41056 8.052 1.76833L7.42267 3.1051L6.01467 3.31892C5.92242 3.33323 5.83581 3.37426 5.76461 3.43738C5.69341 3.50051 5.64045 3.58322 5.61168 3.6762C5.58292 3.76919 5.57951 3.86874 5.60183 3.96365C5.62415 4.05855 5.67131 4.14504 5.738 4.21336L6.75667 5.25384L6.516 6.72268C6.50019 6.81906 6.51043 6.91816 6.54555 7.00876C6.58068 7.09936 6.63929 7.17786 6.71475 7.23537C6.79022 7.29287 6.87953 7.3271 6.97259 7.33418C7.06565 7.34126 7.15874 7.3209 7.24133 7.27541L8.50067 6.58152L9.75933 7.27541C9.84181 7.32135 9.93498 7.34198 10.0281 7.33494C10.1213 7.32789 10.2107 7.29346 10.286 7.23558C10.3615 7.17805 10.4201 7.09951 10.4552 7.00887C10.4903 6.91822 10.5005 6.81909 10.4847 6.72268L10.244 5.25384L11.2627 4.21336C11.3295 4.14511 11.3768 4.0586 11.3992 3.96364C11.4216 3.86867 11.4182 3.76903 11.3893 3.676Z'
                    fill='white'
                />
            </g>
        </svg>
    )
}

export default LeadersIcon
