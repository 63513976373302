import { NavLink, useMatch, useResolvedPath } from 'react-router-dom'
import styles from './EarnNavigationBar.module.scss'
import Tasks from '../../assets/icons/Tasks'
import Lootbox from '../../assets/icons/Lootbox'
import LeadersIcon from '../../assets/icons/LeadersIcon'
import Lottery from '../../assets/icons/Lottery'

type linkType = {
    linkTo: string
    title: string
}

function GenerateIcon({ icon, isActive }: { icon: string; isActive: boolean }) {
    switch (icon) {
        case 'Tasks':
            return <Tasks isActive={isActive} />
        case 'Lootbox':
            return <Lootbox isActive={isActive} />
        case 'Leaders':
            return <LeadersIcon isActive={isActive} />
        case 'Lottery':
            return <Lottery isActive={isActive} />
        default:
            return
    }
}

function NavigationBarLink({ link }: { link: linkType }) {
    const { linkTo, title } = link

    let resolved = useResolvedPath(link.linkTo)
    let match = useMatch({ path: resolved.pathname, end: true })

    return (
        <NavLink
            className={({ isActive }) => {
                const linkClasses = [styles.link]
                if (isActive) linkClasses.push(styles.linkActive)
                return linkClasses.join(' ')
            }}
            to={`${linkTo}`}
            style={{ textDecoration: 'none' }}
        >
            <div>
                <GenerateIcon icon={link.title} isActive={!!match} />
            </div>
            <span>{title}</span>
        </NavLink>
    )
}

function EarnNavigationBar() {
    const links = [
        {
            linkTo: '/earn/tasks',
            title: 'Tasks',
        },
        {
            linkTo: '/earn/lootbox',
            title: 'Lootbox',
        },

        {
            linkTo: '/earn/leaders',
            title: 'Leaders',
        } /*
    {
      linkTo: "/earn/lottery",
      title: "Lottery",
    },*/,
    ]

    return (
        <div className={styles.earnNavBarContainer}>
            <div>
                <nav className={styles.earnNavBar}>
                    {links.map((link, idx) => {
                        return <NavigationBarLink link={link} key={idx} />
                    })}
                </nav>
            </div>
        </div>
    )
}

export default EarnNavigationBar
