function DataSafetyIcon() {
    return (
        <svg
            width='39'
            height='39'
            viewBox='0 0 39 39'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <g filter='url(#filter0_d_1_721)'>
                <path
                    d='M9.7994 7.11118C9.41121 7.26427 9.01208 7.6634 8.86445 8.06253C8.76057 8.33044 8.74963 8.489 8.74963 9.42942V10.4901L11.5818 10.5065C14.2773 10.5284 14.4249 10.5339 14.7475 10.6432C15.2451 10.8072 15.5403 11.0205 16.1855 11.6711L16.7596 12.2452H23.2495H29.745V10.3042C29.745 8.4726 29.7395 8.35231 29.6301 8.06253C29.4771 7.65794 29.0834 7.26427 28.6788 7.11118C28.3726 6.99637 28.3671 6.99637 19.2309 7.00183C10.1767 7.00183 10.0837 7.00183 9.7994 7.11118Z'
                    fill='#FFDE7D'
                />
                <path
                    d='M8.04977 12.36C7.66157 12.5131 7.26244 12.9122 7.11482 13.3113C7 13.6175 7 13.6503 7 20.9932C7 28.3361 7 28.3689 7.11482 28.6751C7.26791 29.0797 7.66157 29.4734 8.06617 29.6265C8.36688 29.7413 8.41062 29.7413 15.1794 29.7413C18.9247 29.7413 21.981 29.7249 21.9701 29.6975C21.5218 28.8282 21.2757 28.1393 21.1281 27.3301C21.0461 26.8982 21.0297 26.4607 21.0297 24.6018L21.0242 22.3874L21.1664 22.0867C21.3468 21.704 21.5491 21.4634 21.8389 21.2994C21.9592 21.2228 23.1183 20.7417 24.4141 20.2278C26.6175 19.3475 26.787 19.2873 27.1205 19.2873C27.4541 19.2873 27.6126 19.342 29.4169 20.0637C30.4831 20.4902 31.3907 20.8511 31.4289 20.8675C31.4782 20.8893 31.4946 20.3207 31.4946 18.1282C31.4946 15.4601 31.4891 15.3562 31.3797 15.0609C31.2267 14.6563 30.833 14.2627 30.4284 14.1096C30.1277 13.9948 30.0839 13.9948 23.1402 13.9948H16.1581L15.3653 13.1965C14.9279 12.7591 14.5069 12.3654 14.4249 12.3217C14.2992 12.2561 13.7852 12.2452 11.3084 12.2506C8.45983 12.2506 8.32861 12.2561 8.04977 12.36Z'
                    fill='#FFDE7D'
                />
                <path
                    d='M24.8624 21.8571L22.741 22.7101L22.7574 24.9572L22.7792 27.1989L22.9269 27.6801C23.4791 29.457 24.7694 30.7911 26.4862 31.3488C27.022 31.5237 27.2407 31.5237 27.7547 31.3542C29.4824 30.7747 30.7673 29.4515 31.3141 27.6855L31.4617 27.1989L31.4836 24.9572L31.5 22.7101L29.3512 21.8517C28.1702 21.3815 27.1533 20.9933 27.0931 20.9987C27.033 20.9987 26.027 21.3869 24.8624 21.8571ZM28.8263 24.6346C29.1489 24.7987 29.3403 25.2197 29.2419 25.5696C29.1653 25.8703 27.1861 27.8496 26.8908 27.9261C26.5245 28.0245 26.3331 27.9261 25.7098 27.3137C25.3982 27.013 25.1084 26.6959 25.0647 26.6139C24.8897 26.2804 25.0209 25.7664 25.3326 25.5587C25.7098 25.3126 26.0652 25.3618 26.448 25.7117L26.6885 25.9359L27.3337 25.2907C28.1374 24.4925 28.3507 24.3995 28.8263 24.6346Z'
                    fill='#FFDE7D'
                />
            </g>
            <defs>
                <filter
                    id='filter0_d_1_721'
                    x='0.411766'
                    y='0.411815'
                    width='37.6764'
                    height='37.6571'
                    filterUnits='userSpaceOnUse'
                    color-interpolation-filters='sRGB'
                >
                    <feFlood flood-opacity='0' result='BackgroundImageFix' />
                    <feColorMatrix
                        in='SourceAlpha'
                        type='matrix'
                        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                        result='hardAlpha'
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation='3.29412' />
                    <feComposite in2='hardAlpha' operator='out' />
                    <feColorMatrix
                        type='matrix'
                        values='0 0 0 0 1 0 0 0 0 0.871729 0 0 0 0 0.491667 0 0 0 0.27 0'
                    />
                    <feBlend
                        mode='normal'
                        in2='BackgroundImageFix'
                        result='effect1_dropShadow_1_721'
                    />
                    <feBlend
                        mode='normal'
                        in='SourceGraphic'
                        in2='effect1_dropShadow_1_721'
                        result='shape'
                    />
                </filter>
            </defs>
        </svg>
    )
}

export default DataSafetyIcon
