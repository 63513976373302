import {
    QuestInterface,
    API,
    QUEST_NOT_COMPLETED,
    QUEST_COMPLETED,
    QUEST_NEWLY_COMPLETED,
    QUEST_WAITING,
    QUEST_WAIT_SECONDS,
    QUEST_NEWLY_NOT_COMPLETED,
} from '../../constants'
import axios from 'axios'
import { useEffect, useState, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { WebAppContext } from '../../contexts/WebAppContexts'
import { updateQuestStatus } from '../../store/slices/data'
import styles from './QuestCard.module.scss'
import GreeenDoneIcon from '../../assets/icons/GreenDoneIcon'
import RedErrorIcon from '../../assets/icons/RedErrorIcon.tsx'

import { useAuthToken } from '../../hooks/useAuthToken.tsx'
import { useNavigate } from 'react-router-dom'
// import CircularProgress from "@mui/joy/CircularProgress";

function QuestCard({ quest }: { quest: QuestInterface }) {
    const { WebApp } = useContext(WebAppContext)
    const dispatch = useDispatch()
    const authToken = useAuthToken()

    const [newCompletedQuest, setNewCompletedQuest] = useState<QuestInterface | undefined>()
    const [errorCompletedQuest, setErrorCompletedQuest] = useState<QuestInterface | undefined>()

    const navigate = useNavigate()
    const [showAlert, setShowAlert] = useState(false)
    const [animate, setAnimate] = useState<'open' | 'close'>('open')
    const [wait, setWait] = useState(false)

    const [error, setError] = useState(false)

    useEffect(() => {
        if (quest.status === QUEST_NEWLY_COMPLETED) {
            setNewCompletedQuest(quest)
        }
        if (quest.status === QUEST_NEWLY_NOT_COMPLETED) {
            setErrorCompletedQuest(quest)
        }
    }, [quest])

    useEffect(() => {
        if (error) {
            setTimeout(() => {
                setAnimate('close')
            }, 2000)
            setTimeout(() => {
                setError(false)
                window.location.reload()
            }, 2500)
        }
    }, [error])

    useEffect(() => {
        if (newCompletedQuest) {
            setShowAlert(true)
            setTimeout(() => {
                setAnimate('close')
            }, 2000)
            setTimeout(() => {
                setShowAlert(false)
                dispatch(
                    updateQuestStatus({
                        status: QUEST_COMPLETED,
                        quest_id: newCompletedQuest.quest_id,
                    }),
                )
                setNewCompletedQuest(undefined)
                setWait(false)
            }, 2500)
        }
    }, [newCompletedQuest, dispatch])

    useEffect(() => {
        if (errorCompletedQuest) {
            setShowAlert(true)
            setTimeout(() => {
                setAnimate('close')
            }, 3500)
            setTimeout(() => {
                setShowAlert(false)
                setErrorCompletedQuest(undefined)
                setWait(false)
            }, 3500)
        }
    }, [errorCompletedQuest, dispatch])

    function startQuest(quest: QuestInterface) {
        setWait(true)
        axios
            .post(`${API}/tg/quests/start`, {
                auth_token: authToken,
                quest_id: quest.quest_id,
            })
            .then((resp) => {
                if (resp.data.status === 'ok') {
                    setWait(false)
                    setTimeout(() => {
                        window.location.reload()
                    }, QUEST_WAIT_SECONDS * 1000)
                    dispatch(
                        updateQuestStatus({
                            status: QUEST_WAITING,
                            quest_id: quest.quest_id,
                        }),
                    )
                    if (quest.url.startsWith('https://web.dormint.io')) {
                        navigate(quest.url.slice('https://web.dormint.io'.length, quest.url.length))
                    } else if (quest.url.startsWith('https://t.me/')) {
                        WebApp?.openTelegramLink(quest.url)
                    } else {
                        WebApp?.openLink(quest.url, { try_instant_view: true })
                    }
                }
            })
            .catch(() => {
                navigate('/error')
            })
    }

    return (
        <>
            {error && (
                <div className={styles[`${animate === 'open' ? 'alert_open' : 'alert_close'}`]}>
                    Something went wrong!
                </div>
            )}
            {showAlert && newCompletedQuest && (
                <div className={styles[`${animate === 'open' ? 'alert_open' : 'alert_close'}`]}>
                    <GreeenDoneIcon />
                    You received +{newCompletedQuest.reward} Sleepcoins!
                </div>
            )}
            {showAlert && errorCompletedQuest && (
                <div className={styles[`${animate === 'open' ? 'alert_open' : 'alert_close'}`]}>
                    <RedErrorIcon />
                    You haven't completed the quest, please try again :(
                </div>
            )}
            <div
                className={styles.questDiv}
                onClick={() => {
                    if (
                        (quest.status === QUEST_NEWLY_NOT_COMPLETED ||
                            quest.status === QUEST_NOT_COMPLETED) &&
                        !wait
                    ) {
                        startQuest(quest)
                    } else {
                        console.log(quest)
                        if (quest.url.startsWith('https://web.dormint.io')) {
                            navigate(
                                quest.url.slice('https://web.dormint.io'.length, quest.url.length),
                            )
                        } else if (quest.url.startsWith('https://t.me/')) {
                            WebApp?.openTelegramLink(quest.url)
                        } else {
                            WebApp?.openLink(quest.url, { try_instant_view: true })
                        }
                    }
                }}
                style={{
                    cursor:
                        quest.status === QUEST_NEWLY_COMPLETED || quest.status === QUEST_COMPLETED
                            ? ''
                            : 'pointer',
                }}
            >
                <div className={styles.questInfo}>
                    <div className={styles.imgContainer}>
                        <img src={quest.icon} width={20} alt='dormint quest' />
                    </div>
                    <div className={styles.textDiv}>{quest.name}</div>
                </div>
                <div className={styles.questInfo}>
                    <span
                        style={{
                            whiteSpace: 'nowrap',
                            color:
                                quest.status === QUEST_COMPLETED ||
                                quest.status === QUEST_NEWLY_COMPLETED
                                    ? '#AFFB65'
                                    : '#fff',
                        }}
                    >
                        +{quest.reward} SC
                    </span>
                    {quest.status === QUEST_COMPLETED || quest.status === QUEST_NEWLY_COMPLETED ? (
                        <div>
                            <GreeenDoneIcon />
                        </div>
                    ) : quest.status === QUEST_WAITING ? (
                        <div className={styles.circle} />
                    ) : (
                        <div className={styles.circle} />
                    )}
                </div>
            </div>
        </>
    )
}

export default QuestCard
