function BackArrow({ width, height }: { width: string; height: string }) {
    return (
        <svg width={width} height={height} viewBox='0 0 24 24' fill='none'>
            <path
                d='M15 5L8 12L15 19'
                stroke='white'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}

export default BackArrow
