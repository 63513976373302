import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
    FarmingDataInterface,
    FrensDataInterface,
    QUEST_NEWLY_NOT_COMPLETED,
    QUEST_NOT_COMPLETED,
    QUEST_WAITING,
    QuestInterface,
    UserInfoInterface,
    LeaderboardPlayerInterface,
} from '../../constants'

export type DataState = {
    authToken: string | null
    farmingData: FarmingDataInterface | null
    frensData: FrensDataInterface | null
    questsData: Array<QuestInterface> | null
    hasNewQuests: boolean
    userData: UserInfoInterface | null

    tonBalance: number | null
    sleepcoinBalance: number | null

    healthrate: number | null
    username: string | null
    strike: number | null
    leaderboard_current: LeaderboardPlayerInterface | null
    leaderboard_list: Array<LeaderboardPlayerInterface> | null
}

const initialState: DataState = {
    authToken: null,
    farmingData: null,
    tonBalance: null,
    sleepcoinBalance: null,
    frensData: null,
    questsData: null,
    hasNewQuests: false,
    userData: null,
    healthrate: null,
    username: null,
    strike: null,
    leaderboard_current: null,
    leaderboard_list: null,
}

export const dataSlice = createSlice({
    name: 'data',
    initialState: initialState,
    reducers: {
        setLeaderboardCurrent: (state, action) => {
            state.leaderboard_current = action.payload
        },
        setLeaderboardList: (state, action) => {
            state.leaderboard_list = action.payload
        },
        setAuthToken: (state, action: PayloadAction<string>) => {
            state.authToken = action.payload
        },
        setFarming: (state, action) => {
            state.farmingData = action.payload
            state.sleepcoinBalance = action.payload.sleepcoin_balance
        },
        setFrens: (state, action) => {
            state.frensData = { ...state.frensData, ...action.payload }
        },
        setQuests: (state, action) => {
            state.questsData = action.payload
            const numNewQuests = state.questsData?.filter((quest: QuestInterface) => {
                return (
                    quest.status === QUEST_NOT_COMPLETED ||
                    quest.status === QUEST_WAITING ||
                    quest.status === QUEST_NEWLY_NOT_COMPLETED
                )
            }).length
            state.hasNewQuests = numNewQuests !== undefined && numNewQuests > 0
        },
        setHasNewQuests: (state, action) => {
            state.hasNewQuests = action.payload
        },
        setUser: (state, action) => {
            state.userData = action.payload
        },
        setHealthrate: (state, action) => {
            state.healthrate = action.payload
        },
        setUsername: (state, action) => {
            state.username = action.payload
        },
        setStrike: (state, action) => {
            state.strike = action.payload
        },
        addSleepcoinBalance: (state, action) => {
            state.sleepcoinBalance += action.payload
        },
        claimFrensReward: (state, action) => {
            if (state.frensData) {
                state.frensData.available -= action.payload
            }
        },
        updateQuestStatus: (state, action) => {
            if (state.questsData) {
                const { quest_id, status } = action.payload
                const index = state.questsData.findIndex((quest) => quest.quest_id === quest_id)
                if (index !== -1) {
                    state.questsData[index] = { ...state.questsData[index], status }
                }

                const numNewQuests = state.questsData?.filter((quest) => {
                    return (
                        quest.status === QUEST_NOT_COMPLETED ||
                        quest.status === QUEST_WAITING ||
                        quest.status === QUEST_NEWLY_NOT_COMPLETED
                    )
                }).length
                state.hasNewQuests = numNewQuests !== undefined && numNewQuests > 0
            }
        },
        startSleeping: (state, action) => {
            if (state.farmingData) {
                state.farmingData.farming_status = 'farming_status_started'
                state.farmingData.farming_left = action.payload.farming_time
                state.farmingData.farming_speed = action.payload.farming_speed
            }
        },
        verifySleepcoinBalance: (state, action) => {
            state.sleepcoinBalance = action.payload
        },
        sleepingProccess: (state) => {
            if (state.farmingData) {
                if (state.farmingData.farming_left <= 0) {
                    state.farmingData.farming_status = 'farming_status_finished'
                    state.farmingData.farming_value = 0.01
                } else {
                    state.farmingData.farming_value += state.farmingData.farming_speed
                    state.farmingData.farming_left -= 1
                }
            }
        },
        claimSleepReward: (state) => {
            if (state.farmingData && state.sleepcoinBalance) {
                state.sleepcoinBalance += state.farmingData.farming_value
                state.farmingData.farming_value = 0
                state.farmingData.farming_status = 'farming_status_not_started'
            }
        },
        incSleepcoinBalance: (state, action) => {
            if (state.sleepcoinBalance) {
                state.sleepcoinBalance += action.payload
            }
        },
        deleteUserInfo: (state) => {
            state.userData = null
        },
        sleepcoinBalanceVerification: (state, action) => {
            state.sleepcoinBalance = action.payload
        },
        tonBalanceVerification: (state, action) => {
            if (state.tonBalance !== action.payload) {
                state.tonBalance = action.payload
            }
        },
    },
})

export const {
    setFarming,
    setFrens,
    setQuests,
    setHasNewQuests,
    setUsername,
    setUser,
    setHealthrate,
    setStrike,
    addSleepcoinBalance,
    claimFrensReward,
    updateQuestStatus,
    verifySleepcoinBalance,
    startSleeping,
    claimSleepReward,
    sleepingProccess,
    deleteUserInfo,
    sleepcoinBalanceVerification,
    tonBalanceVerification,
    setAuthToken,
    setLeaderboardCurrent,
    setLeaderboardList,
    incSleepcoinBalance,
} = dataSlice.actions
export default dataSlice.reducer
