function LoginIcon() {
    return (
        <svg
            width='11'
            height='13'
            viewBox='0 0 11 13'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M4.77765 0.344795C4.34444 0.414109 3.96816 0.542837 3.67852 0.716123C3.23045 0.98348 2.82199 1.38947 2.55463 1.83011C2.416 2.05786 2.29223 2.37472 2.22291 2.66684C2.15112 2.97628 2.13379 3.59516 2.18825 3.90707C2.34669 4.81807 2.89378 5.59043 3.71813 6.06325C4.5301 6.52865 5.6119 6.56826 6.50062 6.16723C6.79768 6.03355 7.07741 5.82808 7.36705 5.54092C8.01564 4.89233 8.30032 4.15463 8.26071 3.24363C8.22358 2.43414 7.9488 1.79793 7.38438 1.22608C6.93383 0.768109 6.37189 0.473522 5.75053 0.364599C5.55497 0.329942 4.94104 0.317564 4.77765 0.344795Z'
                fill='white'
            />
            <path
                d='M2.45077 6.45671C2.13885 6.50375 1.93091 6.56563 1.67841 6.69189C1.19568 6.92954 0.831776 7.32314 0.544615 7.91479C0.183189 8.65993 0 9.5734 0 10.6527C0 11.3063 0.047035 11.539 0.254979 11.9499C0.33172 12.1009 0.410937 12.2024 0.581748 12.3732C0.82435 12.6183 1.03477 12.7569 1.32936 12.8609C1.73782 13.0094 1.60166 13.0045 5.38674 12.997L8.85 12.9896L9.06042 12.9253C9.32778 12.8411 9.46888 12.7792 9.64712 12.6653C10.0605 12.4029 10.365 11.987 10.4962 11.5167C10.5754 11.2221 10.5977 10.8706 10.5705 10.3284C10.4467 7.90242 9.52582 6.53593 7.95881 6.44928C7.62214 6.43196 7.61719 6.43196 7.04039 6.80328C6.5948 7.08797 6.28536 7.22907 5.87442 7.33552C5.56498 7.41721 5.01046 7.41721 4.7035 7.338C4.26533 7.22412 3.97074 7.08797 3.52515 6.79833C3.36176 6.69189 3.16124 6.56811 3.0746 6.52355C2.93102 6.44928 2.89884 6.44186 2.71565 6.44433C2.60425 6.44681 2.48543 6.45176 2.45077 6.45671Z'
                fill='white'
            />
        </svg>
    )
}

export default LoginIcon
