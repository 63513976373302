function Lootbox({ isActive }: { isActive: boolean }) {
    return isActive ? (
        <svg
            width='28'
            height='25'
            viewBox='0 0 28 25'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <g filter='url(#filter0_d_8088_188)'>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M8.83844 6.55555C7.5096 6.80937 6.47049 7.82913 6.10679 9.2363C6.04439 9.47773 6.02997 9.6656 6.01627 10.4155L6 11.3075H8.00186H10.0037V8.90376V6.5L9.54878 6.50338C9.29854 6.50526 8.97891 6.52873 8.83844 6.55555ZM11.1455 8.90376V11.3075H11.7513H12.3571L12.9961 10.4688C13.6654 9.59037 13.7346 9.52528 14 9.52528C14.2654 9.52528 14.3346 9.59037 15.0039 10.4688L15.6429 11.3075H16.2487H16.8545V8.90376V6.5H14H11.1455V8.90376ZM17.9963 8.90376V11.3075H19.9981H22L21.9837 10.4155C21.97 9.6656 21.9556 9.47773 21.8932 9.2363C21.7174 8.55589 21.4327 8.04009 20.9625 7.5498C20.2742 6.83217 19.4697 6.5 18.4198 6.5H17.9963V8.90376ZM6.01516 15.0728L6.02526 17.6362L6.14718 17.8728C6.28259 18.1357 6.39371 18.2489 6.66371 18.3988L6.84593 18.5H14H21.1541L21.3402 18.3967C21.5766 18.2656 21.752 18.0809 21.8766 17.8321L21.9747 17.6362L21.9848 15.0728L21.9949 12.5094H18.819H15.6429L15.0039 13.3482C14.3414 14.2177 14.2429 14.3122 14 14.3122C13.7571 14.3122 13.6586 14.2177 12.9961 13.3482L12.3571 12.5094H9.18105H6.00507L6.01516 15.0728Z'
                    fill='#FFDE7D'
                />
            </g>
            <defs>
                <filter
                    id='filter0_d_8088_188'
                    x='0'
                    y='0.5'
                    width='28'
                    height='24'
                    filterUnits='userSpaceOnUse'
                    color-interpolation-filters='sRGB'
                >
                    <feFlood flood-opacity='0' result='BackgroundImageFix' />
                    <feColorMatrix
                        in='SourceAlpha'
                        type='matrix'
                        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                        result='hardAlpha'
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation='3' />
                    <feComposite in2='hardAlpha' operator='out' />
                    <feColorMatrix
                        type='matrix'
                        values='0 0 0 0 1 0 0 0 0 0.871729 0 0 0 0 0.491667 0 0 0 0.5 0'
                    />
                    <feBlend
                        mode='normal'
                        in2='BackgroundImageFix'
                        result='effect1_dropShadow_8088_188'
                    />
                    <feBlend
                        mode='normal'
                        in='SourceGraphic'
                        in2='effect1_dropShadow_8088_188'
                        result='shape'
                    />
                </filter>
            </defs>
        </svg>
    ) : (
        <svg
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <g opacity='0.5'>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M2.83844 2.55555C1.5096 2.80937 0.470492 3.82913 0.106794 5.2363C0.0443874 5.47773 0.0299722 5.6656 0.0162707 6.41549L0 7.30751H2.00186H4.00371V4.90376V2.5L3.54878 2.50338C3.29854 2.50526 2.97891 2.52873 2.83844 2.55555ZM5.14551 4.90376V7.30751H5.75127H6.35706L6.99615 6.46875C7.66538 5.59037 7.73464 5.52528 8 5.52528C8.26536 5.52528 8.33462 5.59037 9.00385 6.46875L9.64294 7.30751H10.2487H10.8545V4.90376V2.5H8H5.14551V4.90376ZM11.9963 4.90376V7.30751H13.9981H16L15.9837 6.41549C15.97 5.6656 15.9556 5.47773 15.8932 5.2363C15.7174 4.55589 15.4327 4.04009 14.9625 3.5498C14.2742 2.83217 13.4697 2.5 12.4198 2.5H11.9963V4.90376ZM0.0151645 11.0728L0.0252623 13.6362L0.147185 13.8728C0.282595 14.1357 0.393706 14.2489 0.663705 14.3988L0.845929 14.5H8H15.1541L15.3402 14.3967C15.5766 14.2656 15.752 14.0809 15.8766 13.8321L15.9747 13.6362L15.9848 11.0728L15.9949 8.50939H12.819H9.64294L9.00385 9.34819C8.34136 10.2177 8.24292 10.3122 8 10.3122C7.75708 10.3122 7.65864 10.2177 6.99615 9.34819L6.35706 8.50939H3.18105H0.00506673L0.0151645 11.0728Z'
                    fill='white'
                />
            </g>
        </svg>
    )
}
export default Lootbox
