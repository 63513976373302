function ButtonChatIcon() {
    return (
        <svg width='16' height='16' viewBox='0 0 16 16' fill='none'>
            <path
                d='M2.21826 0.0595121C1.65589 0.19707 1.20399 0.440924 0.81234 0.819209C0.410647 1.20375 0.159589 1.63518 0.0524713 2.12914C-0.0512994 2.59809 -0.0379096 2.70438 0.437427 4.94596C0.678442 6.08394 0.882636 7.04685 0.892679 7.08749L0.909416 7.15627H3.50033C6.07117 7.15627 6.0946 7.15627 6.25862 7.22192C6.48625 7.31259 6.70718 7.54081 6.77413 7.7534C6.84108 7.95974 6.84108 8.04102 6.77413 8.25048C6.70718 8.46307 6.41261 8.7507 6.20172 8.80697C6.0946 8.83511 5.43181 8.84449 3.4836 8.84449H0.909416L0.875941 8.99142C0.855857 9.07583 0.65501 10.0075 0.430732 11.0642C0.139505 12.4366 0.0156494 13.0744 0.00560712 13.287C-0.0512994 14.4187 0.778866 15.4973 2.00068 15.8787C2.28521 15.9694 2.37894 15.985 2.77729 15.9975C3.30284 16.0132 3.62084 15.9569 4.03258 15.7724C4.23008 15.6849 13.5092 10.8797 14.5034 10.3514C15.0557 10.0575 15.4172 9.71986 15.6683 9.26654C16.3679 8.00663 15.926 6.4466 14.6707 5.74005C14.2925 5.52746 4.26355 0.328376 4.03258 0.228333C3.65766 0.0595121 3.33296 -0.00301552 2.86432 0.00011158C2.57979 0.00011158 2.38898 0.0188694 2.21826 0.0595121Z'
                fill='white'
            />
        </svg>
    )
}

export default ButtonChatIcon
