import PageHeader from '../../components/PageHeader'
import AgentHistoryBg from '../../assets/images/agent_history_bg.png'
import styles from './AgentHistory.module.scss'
import { useNavigate } from 'react-router-dom'
// import RightArrow from "../../assets/icons/RightArrow";
import { useEffect, useState } from 'react'
import { useAuthToken } from '../../hooks/useAuthToken.tsx'
import axios from 'axios'
import { API, MessageInteface } from '../../constants.tsx'

function AgentHistory() {
    const navigate = useNavigate()
    const authToken = useAuthToken()
    const [messages, setMessages] = useState<Array<MessageInteface>>([])

    const fetchChatMessages = () => {
        axios
            .post(`${API}/agent/history`, {
                auth_token: authToken,
            })
            .then((resp) => {
                if (resp.data.status === 'ok') {
                    setMessages((_: Array<MessageInteface>) => {
                        return resp.data.messages.map((msg: any) => ({
                            sender: msg.by,
                            text: msg.text,
                        }))
                    })
                }
            })
    }

    useEffect(() => {
        fetchChatMessages()
    }, [])

    const data = [
        {
            code: 'Previos 7 days',
            chats: messages.map((msg: any) => ({
                title: msg.text.slice(0, 30) + '...',
                href: '',
            })),
        },
    ]

    return (
        <>
            <PageHeader />
            <div className={styles.content}>
                <img className={styles.image} src={AgentHistoryBg} alt='agent history' />
                <h1 className={styles.title}>Request History</h1>
                <div className={styles.historyContainer}>
                    {data.map((obj) => {
                        return (
                            <div className={styles.dateBlock}>
                                <h2 className={styles.dateBlockTitle}>{obj.code}</h2>
                                {obj.chats.map((chat) => (
                                    <div
                                        className={styles.chatBlock}
                                        onClick={() => navigate('/agent/history')}
                                    >
                                        <span className={styles.chatBlockTitle}>{chat.title}</span>
                                    </div>
                                ))}
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )
}

export default AgentHistory
