function GradientSVG() {
    const gradientTransform = `rotate(180deg)`
    return (
        <svg style={{ height: 0, position: 'absolute' }}>
            <defs>
                <linearGradient id='gradient' gradientTransform={gradientTransform}>
                    <stop offset='5.66%' stopColor='#3D37CA' />
                    <stop offset='95.08%' stopColor='#6B79FF' />
                </linearGradient>
            </defs>
        </svg>
    )
}

export default GradientSVG
