import styles from './Reward.module.scss'
import reward_img from '../../assets/images/reward.png'
import dormint_coin from '../../assets/images/sleepcoin.png'
import { useSelector } from 'react-redux'
import store, { RootState } from '../../store'
import { DataState, setStrike } from '../../store/slices/data'
import { useNavigate } from 'react-router-dom'
import dormint_logo from '../../assets/images/dormint_full_logo.png'
// import {useAuthToken} from "../../hooks/useAuthToken.tsx";

function Reward() {
    const data = useSelector<RootState>((state) => state.data) as DataState
    const { strike } = data

    const navigate = useNavigate()
    // const [searchParams, _] = useSearchParams();
    // const authToken = useAuthToken();

    return (
        <>
            <div className={styles.upperDiv}>
                <img src={dormint_logo} width={100} alt='' />
            </div>
            <div className={styles.container}>
                <div className={styles.content}>
                    <img
                        src={reward_img}
                        style={{ width: '100vw', marginBottom: '-50px' }}
                        alt=''
                    />
                    <div className={styles.rewardNumber}>
                        <h3>Your daily reward</h3>
                        <div>
                            <img src={dormint_coin} width={30} height={30} alt='' />
                            <span>{strike!.toFixed(1)}</span>
                        </div>
                    </div>
                    <div className={styles.text}>
                        Come back tomorrow for check-in
                        <span> day {(2 + (strike! - 30) / 10).toFixed(0)}</span>
                    </div>
                    <button
                        className={styles.button}
                        onClick={() => {
                            store.dispatch(setStrike(0))
                            navigate(`/`)
                        }}
                    >
                        Continue
                    </button>
                </div>
            </div>
        </>
    )
}

export default Reward
