import { useEffect, useState } from 'react'
import styles from './ChatInput.module.scss'
import ButtonChatIcon from '../../assets/icons/ButtonChatIcon'
import axios from 'axios'
import { API, MessageInteface } from '../../constants.tsx'
import { useAuthToken } from '../../hooks/useAuthToken.tsx'

function ChatInput({
    setMessages,
    blockingLoading,
    setBlockingLoading,
}: {
    setMessages: (messages: Array<MessageInteface>) => void
    blockingLoading: boolean
    setBlockingLoading: (state: boolean) => void
}) {
    const [inputValue, setInputValue] = useState('')
    const authToken = useAuthToken()
    const [count, setCount] = useState(0)

    function handleClickButton() {
        setBlockingLoading(true)
        if (inputValue.length > 0) {
            axios
                .post(`${API}/agent/send_message`, {
                    auth_token: authToken,
                    text: inputValue,
                })
                .then((resp) => {
                    if (resp.data.status === 'ok') {
                        setInputValue('')
                        setMessages(
                            resp.data.messages.map((msg: any) => ({
                                sender: msg.by,
                                text: msg.text,
                            })),
                        )
                        setBlockingLoading(false)
                    }
                })
        }
    }

    useEffect(() => {
        const interval = setInterval(() => {
            setCount((c) => c + 1)
        }, 500)

        return () => clearInterval(interval)
    }, [])

    return (
        <div className={styles.contentContainer}>
            <div className={styles.inputContainer}>
                <input
                    className={styles.input}
                    placeholder='Message AI Sleep agent'
                    value={blockingLoading ? '.'.repeat(1 + (count % 3)) : inputValue}
                    onChange={(e) => {
                        if (!blockingLoading) {
                            setInputValue(e.target.value)
                        }
                    }}
                    onKeyDown={(e) => {
                        if (!blockingLoading) {
                            if (e.key === 'Enter') {
                                e.preventDefault()
                                handleClickButton()
                            }
                        }
                    }}
                />
                <button
                    className={styles.button}
                    style={{
                        opacity: inputValue.length > 0 ? 1 : 0.75,
                    }}
                    onClick={() => {
                        if (!blockingLoading) {
                            handleClickButton()
                        }
                    }}
                >
                    <ButtonChatIcon />
                </button>
            </div>
        </div>
    )
}

export default ChatInput
