function DislikeIcon() {
    return (
        <svg width='18' height='18' viewBox='0 0 18 18' fill='none'>
            <path
                d='M16.4531 12.3665C16.2947 12.546 16.1 12.6897 15.8817 12.7882C15.6635 12.8866 15.4269 12.9375 15.1875 12.9375H11.25V14.0625C11.25 14.8084 10.9537 15.5237 10.4262 16.0512C9.89879 16.5786 9.18342 16.875 8.43749 16.875C8.33299 16.875 8.23054 16.846 8.14162 16.7911C8.05269 16.7362 7.98082 16.6576 7.93406 16.5642L5.27765 11.25H2.24999C1.95162 11.25 1.66548 11.1314 1.4545 10.9204C1.24352 10.7095 1.12499 10.4233 1.12499 10.125V3.93745C1.12499 3.63908 1.24352 3.35294 1.4545 3.14196C1.66548 2.93098 1.95162 2.81245 2.24999 2.81245H14.3437C14.7549 2.81231 15.1519 2.96226 15.4603 3.23414C15.7687 3.50601 15.9672 3.88112 16.0186 4.28902L16.8623 11.039C16.8922 11.2767 16.8712 11.5179 16.8007 11.7468C16.7301 11.9757 16.6116 12.187 16.4531 12.3665ZM2.24999 10.125H5.06249V3.93745H2.24999V10.125ZM15.7458 11.1796L14.902 4.42964C14.8849 4.29368 14.8187 4.16864 14.7159 4.07801C14.6131 3.98739 14.4808 3.93741 14.3437 3.93745H6.18749V10.5546L8.76867 15.7176C9.15123 15.641 9.49544 15.4343 9.74273 15.1325C9.99002 14.8307 10.1251 14.4526 10.125 14.0625V12.375C10.125 12.2258 10.1843 12.0827 10.2897 11.9772C10.3952 11.8717 10.5383 11.8125 10.6875 11.8125H15.1875C15.2673 11.8125 15.3462 11.7955 15.419 11.7627C15.4918 11.7299 15.5567 11.6819 15.6095 11.6221C15.6623 11.5622 15.7018 11.4918 15.7253 11.4155C15.7488 11.3392 15.7558 11.2588 15.7458 11.1796Z'
                fill='white'
                fill-opacity='0.7'
            />
        </svg>
    )
}

export default DislikeIcon
