function AndroidIcon() {
    return (
        <svg
            width='24'
            height='25'
            viewBox='0 0 24 25'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M8.76992 3.86458C8.73927 3.91226 8.72564 3.97016 8.74267 4.00762C8.75629 4.04168 8.9402 4.33797 9.15135 4.66151C9.3625 4.98505 9.53619 5.26432 9.53619 5.28135C9.53619 5.29497 9.42381 5.36989 9.28417 5.44823C8.12624 6.08509 7.30547 7.25323 7.15222 8.48268C7.11476 8.80622 7.10795 9.09571 7.1386 9.17744C7.15903 9.22853 7.61539 9.23193 11.9883 9.23193C16.3612 9.23193 16.8175 9.22853 16.838 9.17744C16.8959 9.02759 16.8278 8.34646 16.7188 7.93778C16.6302 7.61764 16.3986 7.12041 16.1909 6.8105C15.8571 6.30646 15.2441 5.75133 14.6924 5.44823C14.5528 5.36989 14.4404 5.29497 14.4404 5.28135C14.4404 5.26432 14.6141 4.98505 14.8252 4.66151C15.0364 4.33797 15.2203 4.04168 15.2339 4.00762C15.2509 3.97016 15.2373 3.91226 15.2066 3.86118C15.1624 3.79647 15.1283 3.78285 15.0432 3.78966C14.9444 3.79988 14.9001 3.85096 14.5187 4.42993C14.2871 4.77731 14.0964 5.0736 14.0896 5.09403C14.0828 5.11106 13.8955 5.0702 13.6707 4.99868C13.1496 4.83861 12.7614 4.7705 12.2097 4.74666C11.5251 4.716 10.7554 4.82839 10.1458 5.04976L9.92104 5.1349L9.48511 4.45717C9.0628 3.80328 9.04237 3.78285 8.93339 3.78285C8.85506 3.78285 8.80738 3.80669 8.76992 3.86458ZM10.0505 6.77644C10.1662 6.88883 10.1833 6.9297 10.1833 7.05911C10.1833 7.34859 9.91422 7.58018 9.65539 7.51547C9.53279 7.48482 9.34207 7.2941 9.31142 7.1715C9.24671 6.91267 9.4783 6.64362 9.76778 6.64362C9.8972 6.64362 9.93806 6.66065 10.0505 6.77644ZM14.4778 6.72195C14.6141 6.8139 14.7026 7.02505 14.6651 7.1715C14.6515 7.22258 14.5902 7.31794 14.5289 7.37924C14.3859 7.52228 14.2633 7.55293 14.0862 7.49163C13.9057 7.43033 13.7933 7.26345 13.7933 7.05911C13.7933 6.9297 13.8103 6.88883 13.9261 6.77644C14.0385 6.66065 14.0794 6.64362 14.2088 6.64362C14.311 6.64362 14.3995 6.67086 14.4778 6.72195Z'
                fill='white'
            />
            <path
                d='M5.40851 9.47043C5.06454 9.52832 4.70354 9.82462 4.59115 10.1413C4.55709 10.2435 4.54688 10.7782 4.54688 12.791V15.3112L4.62521 15.4644C4.82273 15.8629 5.102 16.0468 5.53793 16.0706C5.88531 16.0877 6.09986 16.023 6.31442 15.8322C6.52898 15.6449 6.63115 15.4576 6.67542 15.1715C6.69926 15.0081 6.70948 14.1737 6.70267 12.6002L6.69245 10.2708L6.6039 10.0834C6.38594 9.62709 5.91596 9.38528 5.40851 9.47043Z'
                fill='white'
            />
            <path
                d='M18.1492 9.47012C17.8188 9.52461 17.5293 9.7562 17.3727 10.0831L17.2841 10.2705V12.7396C17.2841 15.5969 17.2671 15.4369 17.6042 15.774C17.8529 16.0226 18.047 16.0908 18.4386 16.0703C18.8575 16.0465 19.1198 15.883 19.3139 15.5254L19.4297 15.3109V12.7907C19.4297 10.7779 19.4195 10.2432 19.3854 10.141C19.2219 9.68127 18.6702 9.38157 18.1492 9.47012Z'
                fill='white'
            />
            <path
                d='M7.15903 13.3255L7.16925 17.0479L7.27142 17.2318C7.32591 17.3305 7.43149 17.4634 7.50301 17.5281C7.72438 17.7154 7.87082 17.746 8.53493 17.746H9.12411L9.13433 19.0981C9.14454 20.4263 9.14454 20.4569 9.22287 20.6068C9.34548 20.8588 9.48852 21.0053 9.72351 21.121C9.93125 21.2232 9.9585 21.2266 10.2582 21.213C10.4898 21.2028 10.6124 21.1789 10.7112 21.1279C10.9087 21.0257 11.0994 20.8213 11.2016 20.6068L11.2901 20.4195L11.3003 19.081L11.3106 17.746H11.9883H12.666L12.6762 19.081L12.6864 20.4195L12.775 20.6068C12.8772 20.8213 13.0679 21.0257 13.2654 21.1279C13.3642 21.1789 13.4868 21.2028 13.7184 21.213C14.0181 21.2266 14.0453 21.2232 14.2531 21.121C14.4881 21.0053 14.6311 20.8588 14.7537 20.6068C14.832 20.4569 14.832 20.4263 14.8422 19.0981L14.8525 17.746H15.4553C16.0479 17.746 16.0615 17.7426 16.2624 17.6473C16.4804 17.5451 16.6064 17.4225 16.7324 17.1875L16.8073 17.0479L16.8175 13.3255L16.8243 9.60645H11.9883H7.15222L7.15903 13.3255Z'
                fill='white'
            />
        </svg>
    )
}

export default AndroidIcon
