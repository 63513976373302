// Implementation of https://core.telegram.org/bots/webapps#initializing-web-apps as react hook

import { useEffect, useState } from 'react'

/*
export type WebAppUser = {
  id: number;
  username: string;
  photo_url: string;
};

export type MainButtonType = {
  show: () => void;
  onClick: (callback: () => void) => void;
  offClick: (callback: () => void) => void;
  setText: (text: string) => void;
  hide: () => void;
};

export type BackButtonType = {
  show: () => void;
  onClick: (callback: () => void) => void;
  hide: () => void;
};

export type WebAppInitData = {
  user: WebAppUser;
  start_param: string;
};

export type WebApp = {
  sendData: (data: string) => void;
  showAlert: (message: string) => void;
  MainButton: MainButtonType;
  BackButton: BackButtonType;
  initDataUnsafe: WebAppInitData;
  initData: string;
  close: () => void;
  openLink: (link: string, option: string) => void;
  openTelegramLink: (link: string) => void;
  ready: () => void;
};

*/

export const useWebApp = () => {
    const [webApp, setWebApp] = useState<WebApp>()
    useEffect(() => {
        function initTg() {
            if (typeof window !== 'undefined' && window.Telegram && window.Telegram.WebApp) {
                setWebApp(window.Telegram.WebApp)
            } else {
                console.log('Telegram WebApp is undefined, retrying…')
                setTimeout(initTg, 500)
            }
        }
        initTg()
    }, [])
    return webApp
}
