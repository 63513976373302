import { useSearchParams, Navigate } from 'react-router-dom'
import General from '../General'
import Quests from '../Quests'
import { useContext, useEffect } from 'react'
import { WebAppContext } from '../../contexts/WebAppContexts.tsx'
import { createGlobalState } from 'react-hooks-global-state'

const initialState = { showedForceQuest: 0 }
const { useGlobalState } = createGlobalState(initialState)

function GeneralOrQuests() {
    const [showedForceQuest, setShowedForceQuest] = useGlobalState('showedForceQuest')

    const { WebApp } = useContext(WebAppContext)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [searchParams, _] = useSearchParams()
    console.log('ShowedForceQuest', showedForceQuest)

    useEffect(() => {
        setShowedForceQuest(showedForceQuest + 1)
        console.log('EFFECT: ', showedForceQuest)

        return () => {
            setShowedForceQuest(showedForceQuest + 1)
        }
    }, [])

    if (showedForceQuest) {
        return <General />
    }

    if (WebApp?.initDataUnsafe?.start_param?.startsWith('FORCE_')) {
        const forceQuestId = WebApp?.initDataUnsafe?.start_param.slice(
            6,
            WebApp?.initDataUnsafe?.start_param.length,
        )
        return <Navigate to={`/tasks?force_quest_id=${forceQuestId}`} />
    }

    const hasForceQuest = searchParams.get('force_quest_id') !== null

    return hasForceQuest ? <Quests /> : <General />
}

export default GeneralOrQuests
