function CopyButton() {
    return (
        <svg width='18' height='18' viewBox='0 0 18 18' fill='none'>
            <path
                d='M15.1875 2.25003H6.18748C6.0383 2.25003 5.89523 2.30929 5.78974 2.41478C5.68425 2.52027 5.62498 2.66335 5.62498 2.81253V5.62503H2.81248C2.6633 5.62503 2.52023 5.68429 2.41474 5.78978C2.30925 5.89527 2.24998 6.03835 2.24998 6.18753V15.1875C2.24998 15.3367 2.30925 15.4798 2.41474 15.5853C2.52023 15.6908 2.6633 15.75 2.81248 15.75H11.8125C11.9617 15.75 12.1047 15.6908 12.2102 15.5853C12.3157 15.4798 12.375 15.3367 12.375 15.1875V12.375H15.1875C15.3367 12.375 15.4797 12.3158 15.5852 12.2103C15.6907 12.1048 15.75 11.9617 15.75 11.8125V2.81253C15.75 2.66335 15.6907 2.52027 15.5852 2.41478C15.4797 2.30929 15.3367 2.25003 15.1875 2.25003ZM11.25 14.625H3.37498V6.75003H11.25V14.625ZM14.625 11.25H12.375V6.18753C12.375 6.03835 12.3157 5.89527 12.2102 5.78978C12.1047 5.68429 11.9617 5.62503 11.8125 5.62503H6.74998V3.37503H14.625V11.25Z'
                fill='white'
                fill-opacity='0.7'
            />
        </svg>
    )
}

export default CopyButton
