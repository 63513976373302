function UserIcon() {
    return (
        <svg width='20' height='20' viewBox='0 0 20 20' fill='none'>
            <path
                d='M9.77765 4.34382C9.34444 4.41313 8.96816 4.54186 8.67852 4.71515C8.23045 4.9825 7.82199 5.38849 7.55463 5.82913C7.416 6.05688 7.29223 6.37375 7.22291 6.66586C7.15112 6.9753 7.13379 7.59418 7.18825 7.9061C7.34669 8.81709 7.89378 9.58945 8.71813 10.0623C9.5301 10.5277 10.6119 10.5673 11.5006 10.1663C11.7977 10.0326 12.0774 9.8271 12.3671 9.53994C13.0156 8.89136 13.3003 8.15365 13.2607 7.24266C13.2236 6.43316 12.9488 5.79695 12.3844 5.2251C11.9338 4.76713 11.3719 4.47255 10.7505 4.36362C10.555 4.32897 9.94104 4.31659 9.77765 4.34382Z'
                fill='white'
            />
            <path
                d='M7.45077 10.4567C7.13885 10.5037 6.93091 10.5656 6.67841 10.6919C6.19568 10.9295 5.83178 11.3231 5.54462 11.9148C5.18319 12.6599 5 13.5734 5 14.6527C5 15.3063 5.04703 15.539 5.25498 15.9499C5.33172 16.1009 5.41094 16.2024 5.58175 16.3732C5.82435 16.6183 6.03477 16.7569 6.32936 16.8609C6.73782 17.0094 6.60166 17.0045 10.3867 16.997L13.85 16.9896L14.0604 16.9253C14.3278 16.8411 14.4689 16.7792 14.6471 16.6653C15.0605 16.4029 15.365 15.987 15.4962 15.5167C15.5754 15.2221 15.5977 14.8706 15.5705 14.3284C15.4467 11.9024 14.5258 10.5359 12.9588 10.4493C12.6221 10.432 12.6172 10.432 12.0404 10.8033C11.5948 11.088 11.2854 11.2291 10.8744 11.3355C10.565 11.4172 10.0105 11.4172 9.7035 11.338C9.26533 11.2241 8.97074 11.088 8.52515 10.7983C8.36176 10.6919 8.16124 10.5681 8.0746 10.5236C7.93102 10.4493 7.89884 10.4419 7.71565 10.4443C7.60425 10.4468 7.48543 10.4518 7.45077 10.4567Z'
                fill='white'
            />
        </svg>
    )
}

export default UserIcon
