import { useNavigate } from 'react-router-dom'
import ReverseTimeIcon from '../../assets/icons/ReverseTimeIcon'
import AgentAvatar from '../../assets/images/agent_avatar.png'
import styles from './ChatHeader.module.scss'

function ChatHeader() {
    const navigate = useNavigate()
    return (
        <div className={styles.chatHeader}>
            <div
                className={styles.reverseIconContainer}
                onClick={() => {
                    navigate('/agent/history')
                }}
            >
                <ReverseTimeIcon />
            </div>
            <div className={styles.textContainer}>
                <span className={styles.agentName}>AI Sleep agent</span>
                <div className={styles.agentStatusContainer}>
                    <div className={styles.agentStatusIcon} />
                    <span className={styles.agentStatus}>Online</span>
                </div>
            </div>
            <div className={styles.agentAvatarContainer}>
                <img src={AgentAvatar} alt='ai agent' className={styles.agentAvatar} />
            </div>
        </div>
    )
}
export default ChatHeader
