import { Ref } from 'react'
import ChatMessage from '../ChatMessage/ChatMessage'
import styles from './ChatMessageBoard.module.scss'
import { MessageInteface } from '../../constants'

function ChatMessageBoard({
    messages,
    messageBoardRef,
}: {
    messages: Array<MessageInteface>
    messageBoardRef: Ref<HTMLDivElement>
}) {
    return (
        <div className={styles.messageBoard}>
            {messages.reverse().map((obj) => (
                <ChatMessage message={obj} />
            ))}
            <div ref={messageBoardRef} />
        </div>
    )
}

export default ChatMessageBoard
