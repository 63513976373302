function DownArrow({ width, height }: { width: string; height: string }) {
    return (
        <svg width={width} height={height} viewBox='0 0 16 9' fill='none'>
            <path
                d='M15 1L8 8L1 1'
                stroke='white'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}

export default DownArrow
