import styles from './Wallets.module.scss'
import walletsIcon from '../../assets/images/wallets.png'

// @ts-ignore
import FSLAuthorization from 'fsl-authorization'

import { useEffect, useState } from 'react'
import RedErrorIcon from '../../assets/icons/RedErrorIcon.tsx'
import PageHeader from '../../components/PageHeader/index.tsx'
import { useAuthToken } from '../../hooks/useAuthToken.tsx'
import axios from 'axios'
import { API } from '../../constants.tsx'
import { useNavigate } from 'react-router-dom'

function FSLWallet() {
    const authToken = useAuthToken()
    const [error, setError] = useState(false)
    const [animate, setAnimate] = useState<'open' | 'close'>('open')
    // const [tonBalance, setTonBalance] = useState<number | null>(null);
    const [fslWalletConnected, setFslWalletConnected] = useState<boolean | null>(null)

    const navigate = useNavigate()

    useEffect(() => {
        if (!authToken) {
            return
        }
        if (fslWalletConnected === null) {
            axios
                .post(`${API}/fsl/wallet/status`, {
                    auth_token: authToken,
                })
                .then((resp) => {
                    setFslWalletConnected(resp.data.fslWalletConnected)
                })
                .catch(() => {
                    navigate('/error')
                })
        }
    }, [authToken])

    useEffect(() => {
        if (error) {
            setTimeout(() => {
                setAnimate('close')
            }, 2000)
            setTimeout(() => {
                setError(false)
            }, 2000)
        }
    }, [error])

    return (
        <>
            <div className={styles.frensPage}>
                <PageHeader />
                <div className={styles.content} id='scrollableDiv'>
                    {error && (
                        <div
                            className={
                                styles[`${animate === 'open' ? 'alert_open' : 'alert_close'}`]
                            }
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                            }}
                        >
                            <RedErrorIcon />
                            Now we can add only 1 wallet
                        </div>
                    )}
                    <img
                        src={walletsIcon}
                        alt='FAQ'
                        style={{ zIndex: '2', height: 'auto', width: '60vw' }}
                    />

                    <div
                        style={{
                            position: 'relative',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <button
                            onClick={() => {
                                if (fslWalletConnected || !authToken) {
                                    setError(true)
                                } else {
                                    const fslAuthorization = FSLAuthorization.init({
                                        responseType: 'code', // 'code' or 'token'
                                        appKey: 'dfuy!6eE2z',
                                        redirectUri:
                                            'https://api-new.dormint.io/api/auth/fsl/verify', // https://xxx.xxx.com
                                        scope: 'basic,wallet', // Grant Scope
                                        state: authToken,
                                        usePopup: true, // Popup a window instead of jump to
                                    })
                                    console.log(fslAuthorization)
                                    fslAuthorization.signIn().then((code: any) => {
                                        if (code) {
                                            console.log('FSL: ', code)
                                        }
                                    })
                                }
                            }}
                            className={styles.farmingButton}
                            style={{
                                color: '#fff',
                                background: 'linear-gradient(180deg, #6B7AFF 0%, #4940F2 100%)',
                            }}
                        >
                            <span>Connect FSL ID</span>
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FSLWallet
