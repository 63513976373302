import { useState } from 'react'
import BackArrow from '../../assets/icons/BackArrow'
import styles from './SeedPhrase.module.scss'
import Copy from '../../assets/icons/Copy'
import Download from '../../assets/icons/Download'
import { useNavigate } from 'react-router-dom'

function SeedPhrase() {
    const [seedPhrase, _] = useState(
        'decline ethics because farm arch people refuse prize neutral eager hero lounge',
    )

    // const [searchParams, _] = useSearchParams();
    const navigate = useNavigate()

    return (
        <div className={styles.seedPhrasePage}>
            <div className={styles.upperDiv}>
                <div
                    className={styles.icon}
                    style={{ position: 'absolute' }}
                    onClick={() => {
                        navigate(-1)
                    }}
                >
                    <BackArrow width='24px' height='24px' />
                </div>
                <h2 className={styles.title}>Seed Phrase</h2>
            </div>
            <div className={styles.mainDiv}>
                <div className={styles.description}>
                    If you ever change browsers or move computers, you will need this seed phrase to
                    access your accounts. Save them somewhere safe and secret.
                </div>
                <div className={styles.attention}>
                    Do not share this phrase with anyone! These words can be used to steal all of
                    your accounts.
                </div>
                <div className={styles.phraseDiv}>
                    <p>Your seed phrase</p>
                    <div className={styles.phrase}>
                        <span>{seedPhrase}</span>
                        <div>
                            <div
                                className={styles.icon}
                                onClick={() => {
                                    navigator.clipboard.writeText(seedPhrase)
                                }}
                            >
                                <Copy width='24px' height='24px' color='#7883FE' />
                            </div>
                            <div className={styles.icon}>
                                <Download width='24px' height='24px' color='#7883FE' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button
                className={styles.button}
                onClick={() => {
                    navigate(-1)
                }}
            >
                Close
            </button>
        </div>
    )
}

export default SeedPhrase
