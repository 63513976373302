function TonConnect({ width, height }: { width: string; height: string }) {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={width}
            height={height}
            viewBox='0 0 32 32'
            fill='none'
        >
            <path
                d='M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z'
                fill='#0098EA'
            />
            <path
                d='M21.4629 8.93018H10.5363C8.52724 8.93018 7.25388 11.0974 8.26459 12.8492L15.0081 24.5377C15.4482 25.3009 16.551 25.3009 16.9911 24.5377L23.736 12.8492C24.7453 11.1001 23.472 8.93018 21.4643 8.93018H21.4629ZM15.0026 21.0325L13.534 18.1902L9.99036 11.8523C9.75659 11.4466 10.0454 10.9268 10.5349 10.9268H15.0013V21.0339L15.0026 21.0325ZM22.0061 11.8509L18.4638 18.1916L16.9952 21.0325V10.9255H21.4616C21.9511 10.9255 22.2399 11.4453 22.0061 11.8509Z'
                fill='white'
            />
        </svg>
    )
}

export default TonConnect
