import { useEffect, useState } from 'react'
import styles from './Leaderboard.module.scss'
import { useSelector } from 'react-redux'
import store, { RootState } from '../../store'
import { DataState, setLeaderboardCurrent, setLeaderboardList } from '../../store/slices/data'
import { useAuthToken } from '../../hooks/useAuthToken'
import { API, placeImage } from '../../constants'
import ProfileAvatar from '../../components/ProfileAvatar'
import mini_coin from '../../assets/images/mini-coin.png'
import { LeaderboardPlayerInterface } from '../../constants'
import { useQuery } from 'react-query'
import axios from 'axios'
import InfiniteScroll from 'react-infinite-scroller'
import Loader from '../../components/Loader'
import InvitedIcon from '../../assets/icons/InvitedIcon'
// import PageHeader from "../../components/PageHeader";
import EarnNavigationBar from '../../components/EarnNavigationBar'
import { useWebApp } from '../../hooks/useWebApp.tsx'
import { useNavigate } from 'react-router-dom'

const StarGramLogo = ({ width, height }: { width: number; height: number }) => {
    return (
        <svg height={height} width={width} viewBox='0 0 47.94 47.94'>
            <path
                style={{ fill: '#ED8A19' }}
                d='M26.285,2.486l5.407,10.956c0.376,0.762,1.103,1.29,1.944,1.412l12.091,1.757
            c2.118,0.308,2.963,2.91,1.431,4.403l-8.749,8.528c-0.608,0.593-0.886,1.448-0.742,2.285l2.065,12.042
            c0.362,2.109-1.852,3.717-3.746,2.722l-10.814-5.685c-0.752-0.395-1.651-0.395-2.403,0l-10.814,5.685
            c-1.894,0.996-4.108-0.613-3.746-2.722l2.065-12.042c0.144-0.837-0.134-1.692-0.742-2.285l-8.749-8.528
            c-1.532-1.494-0.687-4.096,1.431-4.403l12.091-1.757c0.841-0.122,1.568-0.65,1.944-1.412l5.407-10.956
            C22.602,0.567,25.338,0.567,26.285,2.486z'
            />
        </svg>
    )
}

const KingUser = ({
    username,
    kingUsername,
    onClick,
}: {
    username: string
    kingUsername: string | null
    onClick: () => void
}) => {
    return (
        <div
            key='king'
            className={styles.leaderCard}
            //id={user.username!}
            onClick={onClick}
            style={{
                backgroundColor: '#353864',
                border: '2px solid rgb(253, 253, 0)',
                cursor: 'pointer',
            }}
        >
            <div>
                {placeImage(0, '36px', '36px')}
                <ProfileAvatar username={username || 'KG'} width={32} height={32} />
                <div
                    className={styles.playerInfoBlock}
                    style={{ height: kingUsername ? '32px' : '16px' }}
                >
                    <span className={styles.username}>
                        {kingUsername ? kingUsername + ' is a King !' : 'Become a King now !'}
                    </span>
                    {kingUsername && (
                        <div>
                            <span className={styles.invited}>
                                {username === kingUsername
                                    ? 'You are the King'
                                    : 'Become a King now !'}
                            </span>
                        </div>
                    )}
                </div>
            </div>
            <div>
                <StarGramLogo width={20} height={20} />
                <span>{150}</span>
            </div>
        </div>
    )
}

function Leaderboard() {
    const data = useSelector<RootState>((state) => state.data) as DataState
    const { username, leaderboard_current, leaderboard_list } = data
    const authToken = useAuthToken()
    const [kingUsername, setKingUsername] = useState<string | null>(null)
    const WebApp = useWebApp()
    const navigate = useNavigate()

    const openPaymentLink = () => {
        axios
            .post(`${API}/tg/leaderboard/invoice`, {
                auth_token: authToken,
            })
            .then((resp) => {
                if (resp.data.status === 'ok') {
                    WebApp?.openTelegramLink(resp.data.invoiceLink)
                    console.log('Invoice', resp.data.invoiceLink)
                    setTimeout(() => {
                        setFetch(true)
                    }, 3000)
                } else {
                    window.location.reload()
                }
            })
            .catch((err) => {
                console.error(err)
                navigate('/error')
                window.location.reload()
            })
    }
    const leaderboardCurrentRequest = useQuery(
        'leaderboardCurrentRequest',
        () =>
            axios
                .post(`${API}/tg/leaderboard/current`, {
                    auth_token: authToken,
                })
                .catch(() => {
                    navigate('/error')
                }),
        { staleTime: Infinity },
    )

    useEffect(() => {
        if (leaderboardCurrentRequest.isFetched) {
            store.dispatch(setLeaderboardCurrent(leaderboardCurrentRequest.data?.data.player))
        }
    }, [leaderboardCurrentRequest.isFetched])

    const leaderboardListRequest = useQuery(
        'leaderboardRequest',
        () =>
            axios
                .get(`${API}/tg/leaderboard/list`, {
                    params: {
                        offset: 0,
                        limit: 10,
                    },
                })
                .catch(() => {
                    navigate('/error')
                }),
        { staleTime: Infinity },
    )

    useEffect(() => {
        if (leaderboardListRequest.isFetched) {
            store.dispatch(setLeaderboardList(leaderboardListRequest.data?.data.players))
            setKingUsername(leaderboardListRequest.data?.data.king)
        }
    }, [leaderboardListRequest.isFetched])

    const [visibleUser, setVisibleUser] = useState(false)

    useEffect(() => {
        const handleScroll = () => {
            const userElement = document.getElementById(username!)
            if (userElement) {
                const rect = userElement.getBoundingClientRect()
                const isVisible =
                    rect.top <= 0 || (rect.top <= window.innerHeight && rect.bottom >= 0)
                setVisibleUser(isVisible)
            }
        }

        window.addEventListener('scroll', handleScroll)
        handleScroll()

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [username, leaderboard_list])

    const [fetch, setFetch] = useState(true)

    function loadMore() {
        if (leaderboard_list) {
            axios
                .get(`${API}/tg/leaderboard/list`, {
                    params: {
                        auth_token: authToken,
                        offset: leaderboard_list.length,
                        limit: 10,
                    },
                })
                .then((resp) => {
                    console.log('requested scroll')
                    if (resp.data.players.length > 0) {
                        store.dispatch(
                            setLeaderboardList([...leaderboard_list!, ...resp.data.players]),
                        )
                        setKingUsername(resp.data.king)
                    } else {
                        setFetch(false)
                    }
                })
                .catch(() => {
                    navigate('/error')
                })
        }
    }

    return !leaderboardCurrentRequest.isFetched || !leaderboardListRequest.isFetched ? (
        <Loader />
    ) : (
        <div className={styles.leaderboardPage}>
            <EarnNavigationBar />
            <div className={styles.content} id='scrollableDiv'>
                <InfiniteScroll
                    className={styles.leadersBlock}
                    threshold={10}
                    loadMore={loadMore}
                    hasMore={fetch}
                    useWindow={false}
                >
                    <KingUser
                        username={username || 'Anonymous'}
                        kingUsername={kingUsername}
                        onClick={openPaymentLink}
                    />
                    {leaderboard_list?.map((user: LeaderboardPlayerInterface, idx: number) => {
                        return (
                            <div
                                key={idx}
                                className={styles.leaderCard}
                                id={user.username!}
                                style={{ backgroundColor: idx < 3 ? '#353864' : '#2D2F54' }}
                            >
                                <div>
                                    {placeImage(user.place, '36px', '36px')}
                                    <ProfileAvatar
                                        username={user.username}
                                        width={32}
                                        height={32}
                                    />
                                    <div className={styles.playerInfoBlock}>
                                        <span className={styles.username}>
                                            {user.username || 'Anonymous'}
                                        </span>
                                        <div>
                                            <InvitedIcon width={10} height={10} />
                                            <span className={styles.invited}>{user.invited}</span>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <img src={mini_coin} width={10} height={10} alt='' />
                                    <span>{user.reward}</span>
                                </div>
                            </div>
                        )
                    })}
                </InfiniteScroll>
            </div>
            <div
                className={styles.leaderCard}
                style={{
                    zIndex: '2',
                    position: 'absolute',
                    backgroundColor: '#353864',
                    width: 'calc(100vw - 32px)',
                    bottom: '5px',
                    margin: 'auto',
                    left: 0,
                    right: 0,
                    transition: '0.25s ease-in-out',
                    opacity: visibleUser ? 0 : 1,
                }}
            >
                <div>
                    {placeImage(leaderboard_current?.place, '36px', '36px')}
                    <ProfileAvatar
                        username={leaderboard_current?.username}
                        width={32}
                        height={32}
                    />
                    <div className={styles.playerInfoBlock}>
                        <span className={styles.username}>{leaderboard_current?.username}</span>
                        <div>
                            <InvitedIcon width={10} height={10} />
                            <span className={styles.invited}>{leaderboard_current?.invited}</span>
                        </div>
                    </div>
                </div>
                <div>
                    <img src={mini_coin} width={10} height={10} alt='' />
                    <span>{leaderboard_current?.reward}</span>
                </div>
            </div>
        </div>
    )
}

export default Leaderboard
