import PageHeader from '../../components/PageHeader'
import styles from './Subscription.module.scss'
import background_image from '../../assets/images/general_bg.png'
import SubscriptionPillow from '../../assets/images/subscription_pillow.png'
import InternetSecurityIcon from '../../assets/icons/InternetSecurityIcon'
import BlockIcon from '../../assets/icons/BlockIcon'
import RocketIcon from '../../assets/icons/RocketIcon'
import { API, coinIcon, MONTHLY_VPN_COST, MONTHLY_3_VPN_COST } from '../../constants.tsx'
import { createRef, useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { WebAppContext } from '../../contexts/WebAppContexts.tsx'
import { useAuthToken } from '../../hooks/useAuthToken.tsx'
import { useNavigate } from 'react-router-dom'

function Subscription() {
    const [choosed, setChoosed] = useState<string>('3months')
    const { WebApp } = useContext(WebAppContext)
    const authToken = useAuthToken()

    const navigate = useNavigate()

    const openPaymentLink = (invoiceType: string) => {
        axios
            .post(`${API}/vpn/subscribe`, {
                auth_token: authToken,
                invoiceType: invoiceType,
            })
            .then((resp) => {
                if (resp.data.status === 'ok') {
                    WebApp?.openTelegramLink(resp.data.invoiceLink)
                    console.log('Invoice', resp.data.invoiceLink)
                    setTimeout(() => {
                        window.location.reload()
                    }, 3000)
                    // WebApp?.close();
                } else {
                    window.location.reload()
                }
            })
            .catch(() => {
                navigate('/error')
            })
    }

    const activeStyles = {
        background: 'linear-gradient(180deg,#6b7aff,#404999)',
        cursor: 'pointer',
    }
    const nonActiveStyles = {
        background: '#2d2f54',
        cursor: 'pointer',
    }
    // const nonActiveBackgroundColor = "#2d2f54"

    const buttonRef = createRef<HTMLButtonElement>()
    const [textDivTop, setTextDivTop] = useState(0)
    const [showInfo, setShowInfo] = useState(false)

    useEffect(() => {
        if (buttonRef.current) {
            setTextDivTop(buttonRef.current.offsetTop + buttonRef.current.offsetHeight + 10)
        }
    }, [])
    useEffect(() => {
        if (showInfo) {
            setTimeout(() => {
                setShowInfo(false)
            }, 3000)
        }
    }, [showInfo])

    return (
        <div className={styles.subscriptionPage}>
            <PageHeader />
            <div
                style={{
                    borderRadius: '25px 25px 0 0',
                    boxShadow: '0 -8px 12px -5px #F3D45D',
                    background: '#FFD952',
                    paddingTop: '2px',
                }}
            >
                <div className={styles.content}>
                    <img className={styles.bg_image} src={background_image} alt='' />
                    <img className={styles.pillowImage} src={SubscriptionPillow} alt='pillow' />
                    <h2 className={styles.subscriptionTitle}>
                        Get Dormint VPN and protect your online world as securely as Dormint
                        safeguards your sleep
                        <div>
                            <button
                                className={styles.lootbox_buy_button}
                                style={{ borderRadius: '16px', padding: '6px 11.5px' }}
                                ref={buttonRef}
                                onClick={() => {
                                    setShowInfo(true)
                                }}
                            >
                                <div>
                                    <span>?</span>
                                </div>
                            </button>
                        </div>
                        <div
                            className={styles.infoAlert}
                            style={{
                                display: showInfo ? undefined : 'none',
                                top: textDivTop,
                                right: '18px',
                            }}
                        >
                            1) Purchase Subscription: Go to the VPN section in Dormint Mini, choose
                            a plan, and pay with Telegram Stars. <br />
                            2) Download App: After purchase, follow the VPN section's link to
                            download the app for your OS.
                            <br />
                            3) Enter Credentials: Copy your Login and Password from Dormint Mini,
                            then paste into the VPN app. <br />
                            4) Connect VPN: Open the app, enter credentials, select a server, and
                            tap "Connect." <br />
                        </div>
                    </h2>
                    <h3 className={styles.subscriptionSubTitle}></h3>
                    <div className={styles.blocksContainer}>
                        <div className={styles.characteristicBlock}>
                            <RocketIcon />
                            <span>
                                High <br /> speed
                            </span>
                        </div>
                        <div
                            className={styles.characteristicBlock}
                            style={{
                                padding: '8.78px 21.07px',
                                gap: '4px',
                                borderRadius: '14.05px',
                                height: '115%',
                                fontSize: '12px',
                                lineHeight: '14.63px',
                            }}
                        >
                            <BlockIcon />
                            <span>Ad Blocking</span>
                        </div>
                        <div className={styles.characteristicBlock}>
                            <InternetSecurityIcon />
                            <span>
                                Internet <br />
                                Security
                            </span>
                        </div>
                        <div
                            onClick={() => {
                                setChoosed('month')
                            }}
                            style={choosed === 'month' ? activeStyles : nonActiveStyles}
                        >
                            <span>Month</span>
                            <p style={{ gap: '3px', display: 'flex', alignItems: 'center' }}>
                                {MONTHLY_VPN_COST}
                                {coinIcon('tgstar', '14px', '14px')}
                            </p>
                            <p>
                                <br />
                            </p>
                        </div>
                        <div
                            onClick={() => {
                                setChoosed('3months')
                            }}
                            style={choosed === '3months' ? activeStyles : nonActiveStyles}
                        >
                            <span>3 Months</span>
                            <div>
                                <span>{MONTHLY_VPN_COST * 3}</span>
                                <span
                                    style={{
                                        gap: '3px',
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    {MONTHLY_3_VPN_COST}
                                    {coinIcon('tgstar', '14px', '14px')}
                                </span>
                            </div>
                            <p>
                                {(
                                    100 -
                                    (100 * MONTHLY_3_VPN_COST) / (MONTHLY_VPN_COST * 3)
                                ).toFixed(0)}
                                % discount
                            </p>
                        </div>
                    </div>
                    <button
                        className={styles.subscriptionButton}
                        onClick={() => {
                            openPaymentLink(choosed)
                        }}
                    >
                        Buy
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Subscription
