import { useEffect, useState } from 'react'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// import useGeoLocation from "react-ipgeolocation";
import axios from 'axios'
import { API, BANNER_TIMEOUT } from '../../constants'
import styles from './General.module.scss'
import { useNavigate } from 'react-router-dom'
import Loader from '../../components/Loader'
import FarmingCard from '../../components/FarmingCard'
import { useSelector } from 'react-redux'
import store, { RootState } from '../../store'
import {
    DataState,
    setFarming,
    setHasNewQuests,
    setHealthrate,
    setStrike,
} from '../../store/slices/data'
import { useAuthToken } from '../../hooks/useAuthToken.tsx'
import PageHeader from '../../components/PageHeader/index.tsx'

function General() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // const [searchParams, _] = useSearchParams();
    const authToken = useAuthToken()

    const data = useSelector<RootState>((state) => state.data) as DataState
    const [isFarmingRequested, setIsFarmingRequested] = useState<boolean>(false)

    const { farmingData, healthrate, sleepcoinBalance } = data
    const [isLoadingPeriod, setIsLoadingPeriod] = useState<boolean>(true)
    setTimeout(() => {
        setIsLoadingPeriod(false)
    }, BANNER_TIMEOUT)

    const navigate = useNavigate()
    // const geo = useGeoLocation();

    useEffect(() => {
        if (!authToken) {
            return
        }
        if (farmingData === null) {
            console.log('REQUEST!', authToken)
            // console.log("REQUEST!", geo, authToken);
            // if (geo.country || !geo.isLoading) {
            setIsFarmingRequested(true)

            !isFarmingRequested &&
                axios
                    .post(`${API}/tg/farming/status`, {
                        auth_token: authToken,
                        // geo: geo.country,
                        geo: 'Unknown',
                    })
                    .then((resp) => {
                        store.dispatch(setFarming(resp.data))
                        store.dispatch(setHealthrate(resp.data.health_rate))
                        store.dispatch(setStrike(resp.data.strike))
                        store.dispatch(setHasNewQuests(resp.data.has_new_quests))

                        if (resp.data.strike > 0) {
                            navigate(`/reward`)
                        }
                    })
                    .catch((err) => {
                        console.error(err)
                        window.location.reload()
                    })
            // }
        }
        // }, [geo, authToken]);
    }, [authToken])

    return !farmingData || isLoadingPeriod || sleepcoinBalance === null ? (
        <Loader />
    ) : (
        <div className={styles.generalPage}>
            <PageHeader />
            <div
                style={{
                    borderRadius: '25px 25px 0 0',
                    boxShadow: '0 -8px 12px -5px #F3D45D',
                    background: '#FFD952',
                    paddingTop: '2px',
                    height: '50px',
                }}
            >
                <div className={styles.content}>
                    <FarmingCard
                        farmingData={farmingData}
                        healthrate={healthrate!}
                        sleepcoinBalance={sleepcoinBalance}
                    />
                </div>
            </div>
        </div>
    )
}

export default General
