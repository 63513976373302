import styles from './Loader.module.scss'

function Loader() {
    return (
        <div className={styles.loaderPage}>
            <div className={styles.loader}>
                <div className={styles.imgloadingContainer}>
                    <span />
                </div>
                <img
                    width='100'
                    height='100'
                    data-no-lazy='1'
                    alt='loader image'
                    src='https://dormint.io/wp-content/uploads/2022/08/sleep14.svg'
                />
            </div>
        </div>
    )
}

export default Loader
