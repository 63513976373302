import styles from './AgentSubscription.module.scss'
import PageHeader from '../../components/PageHeader'
import AgentBg from '../../assets/images/agent_bg.png'
import AgentPillow from '../../assets/images/agent_pillow.png'
import { coinIcon, WEEKLY_AGENT_COST, DAILY_AGENT_COST, API } from '../../constants'
import { createRef, useContext, useEffect, useState } from 'react'
import DataSafetyIcon from '../../assets/icons/DataSafetyIcon'
import UnlimitedRequestsIcon from '../../assets/icons/UnlimitedRequestsIcon'
import HighSpeedIcon from '../../assets/icons/HightSpeedIcon'
import axios from 'axios'
import { WebAppContext } from '../../contexts/WebAppContexts.tsx'
import { useAuthToken } from '../../hooks/useAuthToken.tsx'
import { useNavigate } from 'react-router-dom'

function AgentSubscription() {
    const { WebApp } = useContext(WebAppContext)
    const authToken = useAuthToken()

    const buttonRef = createRef<HTMLButtonElement>()
    const [textDivTop, setTextDivTop] = useState(0)
    const [showInfo, setShowInfo] = useState(false)

    const [choosed, setChoosed] = useState<string>('week')
    // const [inputValue, setInputValue] = useState<string>("");
    const navigate = useNavigate()

    const openPaymentLink = (invoiceType: string) => {
        axios
            .post(`${API}/agent/subscribe`, {
                auth_token: authToken,
                invoiceType: invoiceType,
            })
            .then((resp) => {
                if (resp.data.status === 'ok') {
                    WebApp?.openTelegramLink(resp.data.invoiceLink)
                    console.log('Invoice', resp.data.invoiceLink)
                    setTimeout(() => {
                        window.location.reload()
                    }, 3000)
                    // WebApp?.close();
                } else {
                    window.location.reload()
                }
            })
            .catch(() => {
                navigate('/error')
            })
    }

    const activeStyles = {
        background: 'linear-gradient(180deg,#6b7aff,#404999)',
        cursor: 'pointer',
    }
    const nonActiveStyles = {
        background: '#2d2f54',
        cursor: 'pointer',
    }

    useEffect(() => {
        if (buttonRef.current) {
            setTextDivTop(buttonRef.current.offsetTop + buttonRef.current.offsetHeight + 10)
        }
    }, [])

    useEffect(() => {
        if (showInfo) {
            setTimeout(() => {
                setShowInfo(false)
            }, 3000)
        }
    }, [showInfo])

    return (
        <>
            <PageHeader />
            <div className={styles.contentContainer}>
                <div className={styles.content}>
                    <img className={styles.bg_image} src={AgentBg} alt='' />
                    <img className={styles.pillowImage} src={AgentPillow} alt='pillow' />
                    <h2 className={styles.subscriptionTitle}>
                        Get your own AI Sleep agent that will answer all of you question regarding
                        improving your sleep
                        <div>
                            <button
                                className={styles.lootbox_buy_button}
                                style={{
                                    borderRadius: '16px',
                                    padding: '6px 11.5px',
                                    top: 20,
                                    right: 18,
                                    position: 'absolute',
                                }}
                                ref={buttonRef}
                                onClick={() => {
                                    setShowInfo(true)
                                }}
                            >
                                <div>
                                    <span>?</span>
                                </div>
                            </button>
                        </div>
                        <div
                            className={styles.infoAlert}
                            style={{
                                display: showInfo ? undefined : 'none',
                                top: textDivTop,
                                right: '18px',
                            }}
                        >
                            info
                        </div>
                    </h2>
                    <h3 className={styles.subscriptionSubTitle}></h3>
                    <div className={styles.blocksContainer}>
                        <div className={styles.characteristicBlock}>
                            <HighSpeedIcon />
                            <span>
                                High speed
                                <br /> answers
                            </span>
                        </div>
                        <div
                            className={styles.characteristicBlock}
                            style={{
                                padding: '8.78px 21.07px',
                                gap: '4px',
                                borderRadius: '14.05px',
                                height: '115%',
                                fontSize: '12px',
                                lineHeight: '14.63px',
                            }}
                        >
                            <UnlimitedRequestsIcon />
                            <span>Unlimited requests</span>
                        </div>
                        <div className={styles.characteristicBlock}>
                            <DataSafetyIcon />
                            <span>
                                Data
                                <br />
                                Safety
                            </span>
                        </div>
                        <div
                            onClick={() => {
                                setChoosed('day')
                            }}
                            style={choosed === 'day' ? activeStyles : nonActiveStyles}
                        >
                            <span>Day</span>
                            <p style={{ gap: '3px', display: 'flex', alignItems: 'center' }}>
                                {DAILY_AGENT_COST}
                                {coinIcon('tgstar', '14px', '14px')}
                            </p>
                            <p>
                                <br />
                            </p>
                        </div>
                        <div
                            onClick={() => {
                                setChoosed('week')
                            }}
                            style={choosed === 'week' ? activeStyles : nonActiveStyles}
                        >
                            <span>Week</span>
                            <div>
                                <span>{(WEEKLY_AGENT_COST * 1.05).toFixed(0)}</span>
                                <span
                                    style={{
                                        gap: '3px',
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    {WEEKLY_AGENT_COST}
                                    {coinIcon('tgstar', '14px', '14px')}
                                </span>
                            </div>
                            <p>
                                {(
                                    100 -
                                    (100 * WEEKLY_AGENT_COST) /
                                        parseFloat((WEEKLY_AGENT_COST * 1.05).toFixed(0))
                                ).toFixed(0)}
                                % discount
                            </p>
                        </div>
                    </div>
                    {/*<div className={styles.inputContainer}>
            <input
              className={styles.inputStyle}
              placeholder="Text"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
          </div>*/}
                    <button
                        className={styles.subscriptionButton}
                        onClick={() => {
                            //   openPaymentLink(choosed);
                            openPaymentLink(choosed)
                        }}
                    >
                        Buy
                    </button>
                </div>
            </div>
        </>
    )
}

export default AgentSubscription
