import Chat from '../../components/Chat/Chat'
import PageHeader from '../../components/PageHeader'
import AgentChatBg from '../../assets/images/agent_chat_bg.png'
import styles from './AgentChat.module.scss'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { API } from '../../constants.tsx'
// import {WebAppContext} from "../../contexts/WebAppContexts.tsx";
import { useAuthToken } from '../../hooks/useAuthToken.tsx'
import { useNavigate } from 'react-router-dom'
import Loader from '../../components/Loader'
import AgentSubscription from '../AgentSubscription/AgentSubscription.tsx'

function AgentChat() {
    // const [expiration, setExpiration] = useState("");
    // const [showCopyAlert, setShowCopyAlert] = useState(false);
    // const [animateCopyAlert, setAnimateCopyAlert] = useState(true);

    const [isActive, setIsActive] = useState<boolean | null>(null)
    const [agentStatusRequested, setAgentStatusRequested] = useState(false)

    // const { WebApp } = useContext(WebAppContext);
    const authToken = useAuthToken()
    const navigate = useNavigate()

    useEffect(() => {
        if (!agentStatusRequested && isActive === null) {
            setAgentStatusRequested(true)
            axios
                .post(`${API}/agent/status`, {
                    auth_token: authToken,
                })
                .then((resp) => {
                    setIsActive(resp.data.isActive)
                    // setExpiration(resp.data.expiration);
                })
                .catch(() => {
                    navigate('/error')
                })
        }
    }, [])

    return isActive === null || authToken === null ? (
        <Loader />
    ) : !isActive ? (
        <AgentSubscription />
    ) : (
        <div style={{ position: 'relative', height: '90%' }}>
            <PageHeader />
            <img src={AgentChatBg} alt='' className={styles.chatBg} />
            <Chat />
        </div>
    )
}

export default AgentChat
