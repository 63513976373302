function ReloadIcon() {
    return (
        <svg width='18' height='18' viewBox='0 0 18 18' fill='none'>
            <path
                d='M15.75 3.37503V6.75003C15.75 6.89921 15.6907 7.04229 15.5852 7.14778C15.4798 7.25327 15.3367 7.31253 15.1875 7.31253H11.8125C11.6633 7.31253 11.5202 7.25327 11.4147 7.14778C11.3093 7.04229 11.25 6.89921 11.25 6.75003C11.25 6.60084 11.3093 6.45777 11.4147 6.35228C11.5202 6.24679 11.6633 6.18753 11.8125 6.18753H13.8298L12.8011 5.15886C11.7557 4.10867 10.3363 3.51618 8.85445 3.51144H8.82281C7.3534 3.50799 5.9417 4.08313 4.89304 5.11245C4.78562 5.21273 4.64322 5.26699 4.4963 5.26362C4.34938 5.26025 4.20962 5.19952 4.10691 5.09442C4.0042 4.98932 3.94669 4.84819 3.9467 4.70124C3.94671 4.55428 4.00423 4.41317 4.10695 4.30808C5.37787 3.06593 7.08737 2.3751 8.86446 2.3855C10.6415 2.3959 12.3428 3.10669 13.5991 4.36362L14.625 5.39229V3.37503C14.625 3.22584 14.6843 3.08277 14.7897 2.97728C14.8952 2.87179 15.0383 2.81253 15.1875 2.81253C15.3367 2.81253 15.4798 2.87179 15.5852 2.97728C15.6907 3.08277 15.75 3.22584 15.75 3.37503ZM13.1069 12.8876C12.0479 13.9221 10.6238 14.4973 9.14339 14.4887C7.663 14.48 6.24571 13.888 5.1989 12.8412L4.17023 11.8125H6.1875C6.33668 11.8125 6.47975 11.7533 6.58524 11.6478C6.69073 11.5423 6.75 11.3992 6.75 11.25C6.75 11.1008 6.69073 10.9578 6.58524 10.8523C6.47975 10.7468 6.33668 10.6875 6.1875 10.6875H2.8125C2.66331 10.6875 2.52024 10.7468 2.41475 10.8523C2.30926 10.9578 2.25 11.1008 2.25 11.25V14.625C2.25 14.7742 2.30926 14.9173 2.41475 15.0228C2.52024 15.1283 2.66331 15.1875 2.8125 15.1875C2.96168 15.1875 3.10475 15.1283 3.21024 15.0228C3.31573 14.9173 3.375 14.7742 3.375 14.625V12.6078L4.40367 13.6364C5.65819 14.8973 7.36199 15.6084 9.14062 15.6136H9.17789C10.9414 15.6182 12.6357 14.9278 13.8937 13.692C13.9965 13.5869 14.054 13.4458 14.054 13.2988C14.054 13.1519 13.9965 13.0107 13.8938 12.9056C13.7911 12.8005 13.6513 12.7398 13.5044 12.7364C13.3575 12.7331 13.2151 12.7873 13.1077 12.8876H13.1069Z'
                fill='white'
                fill-opacity='0.7'
            />
        </svg>
    )
}

export default ReloadIcon
