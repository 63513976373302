function GreeenDoneIcon() {
    return (
        <svg width='22' height='22' viewBox='0 0 22 22' fill='none'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22ZM16.0973 9.22227C16.6342 8.6853 16.6342 7.8147 16.0973 7.27773C15.5603 6.74076 14.6897 6.74076 14.1527 7.27773L9.625 11.8055L7.84727 10.0277C7.3103 9.49076 6.4397 9.49076 5.90273 10.0277C5.36576 10.5647 5.36576 11.4353 5.90273 11.9723L8.65273 14.7223C9.1897 15.2592 10.0603 15.2592 10.5973 14.7223L16.0973 9.22227Z'
                fill='url(#paint0_linear_28_5869)'
            />
            <path
                d='M16.0973 9.22227C16.6342 8.6853 16.6342 7.8147 16.0973 7.27773C15.5603 6.74076 14.6897 6.74076 14.1527 7.27773L9.625 11.8055L7.84727 10.0277C7.3103 9.49076 6.4397 9.49076 5.90273 10.0277C5.36576 10.5647 5.36576 11.4353 5.90273 11.9723L8.65273 14.7223C9.1897 15.2592 10.0603 15.2592 10.5973 14.7223L16.0973 9.22227Z'
                fill='white'
            />
            <defs>
                <linearGradient
                    id='paint0_linear_28_5869'
                    x1='11'
                    y1='0'
                    x2='11'
                    y2='22'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stopColor='#B3FF67' />
                    <stop offset='1' stopColor='#499535' />
                </linearGradient>
            </defs>
        </svg>
    )
}

export default GreeenDoneIcon
