import styles from './ChatMessage.module.scss'
import AgentAvatar from '../../assets/images/agent_avatar.png'
import CopyButton from '../../assets/icons/CopyButton'
import LikeIcon from '../../assets/icons/LikeIcon'
import DislikeIcon from '../../assets/icons/DislikeIcon'
import ReloadIcon from '../../assets/icons/ReloadIcon'
import { MessageInteface } from '../../constants'

function ChatMessage({ message }: { message: MessageInteface }) {
    const { sender, text } = message

    return sender === 'user' ? (
        <div className={styles.userMessage}>{text}</div>
    ) : (
        <div className={styles.agentMessageContainer}>
            <div className={styles.agentAvatarContainer}>
                <img src={AgentAvatar} alt='ai agent' className={styles.agentAvatar} />
            </div>
            <div className={styles.agentMessageBlock}>
                <div className={styles.agentMessage}>{text}</div>
                <div className={styles.buttonGroup}>
                    <button className={styles.button}>
                        <CopyButton />
                    </button>
                    <button className={styles.button}>
                        <LikeIcon />
                    </button>
                    <button className={styles.button}>
                        <DislikeIcon />
                    </button>
                    <button className={styles.button}>
                        <ReloadIcon />
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ChatMessage
