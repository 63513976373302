function HighSpeedIcon() {
    return (
        <svg
            width='41'
            height='37'
            viewBox='0 0 41 37'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <g filter='url(#filter0_d_1_703)'>
                <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M16.3333 7.00005C11.1787 7.00005 7 11.1787 7 16.3334V19.3524C7 22.9946 9.7386 26.0543 13.3585 26.4566C13.5718 26.4802 13.722 26.6772 13.6886 26.8891L13.2759 29.5024C13.1918 30.0351 13.8459 30.3572 14.2168 29.9656L17.3376 26.6715C17.4413 26.562 17.5854 26.5001 17.7362 26.5001H24.6667C29.8213 26.5001 34 22.3214 34 17.1667V16.3334C34 11.1787 29.8213 7.00005 24.6667 7.00005H16.3333ZM15.3409 19.1039C14.8444 19.1039 14.4351 18.6963 14.5085 18.2015C14.5814 17.7104 14.7136 17.2289 14.9031 16.768C15.2075 16.0274 15.6538 15.3546 16.2163 14.7877C16.7788 14.221 17.4466 13.7713 18.1816 13.4646C18.9166 13.1578 19.7044 12.9999 20.5 12.9999C21.2955 12.9999 22.0833 13.1578 22.8183 13.4646C23.5533 13.7713 24.2211 14.221 24.7836 14.7877C25.3461 15.3546 25.7924 16.0274 26.0968 16.768C26.2863 17.2289 26.4185 17.7104 26.4914 18.2015C26.5648 18.6963 26.1555 19.1039 25.659 19.1039C25.1626 19.1039 24.7696 18.694 24.6657 18.2049C24.6117 17.9511 24.5349 17.7022 24.4358 17.4613C24.2217 16.9405 23.9079 16.4672 23.5123 16.0687C23.1168 15.6701 22.6471 15.3539 22.1302 15.1382C21.6134 14.9225 21.0594 14.8114 20.5 14.8114C19.9405 14.8114 19.3865 14.9225 18.8697 15.1382C18.3528 15.3539 17.8831 15.6701 17.4876 16.0687C17.092 16.4672 16.7782 16.9405 16.5641 17.4613C16.465 17.7022 16.3882 17.9511 16.3342 18.2049C16.2303 18.694 15.8373 19.1039 15.3409 19.1039ZM21.9861 17.018C21.6281 16.7985 21.1615 16.913 20.9437 17.2736L19.8598 19.0683C19.2641 19.1452 18.8037 19.6579 18.8037 20.2791C18.8037 20.9534 19.3462 21.4999 20.0153 21.4999C20.6845 21.4999 21.2269 20.9534 21.2269 20.2791C21.2269 20.1335 21.2016 19.9937 21.1552 19.8642L22.2397 18.0683C22.4576 17.7076 22.344 17.2374 21.9861 17.018Z'
                    fill='#FFDE7D'
                />
            </g>
            <defs>
                <filter
                    id='filter0_d_1_703'
                    x='0.411766'
                    y='0.411815'
                    width='40.1765'
                    height='36.3155'
                    filterUnits='userSpaceOnUse'
                    color-interpolation-filters='sRGB'
                >
                    <feFlood flood-opacity='0' result='BackgroundImageFix' />
                    <feColorMatrix
                        in='SourceAlpha'
                        type='matrix'
                        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                        result='hardAlpha'
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation='3.29412' />
                    <feComposite in2='hardAlpha' operator='out' />
                    <feColorMatrix
                        type='matrix'
                        values='0 0 0 0 1 0 0 0 0 0.871729 0 0 0 0 0.491667 0 0 0 0.27 0'
                    />
                    <feBlend
                        mode='normal'
                        in2='BackgroundImageFix'
                        result='effect1_dropShadow_1_703'
                    />
                    <feBlend
                        mode='normal'
                        in='SourceGraphic'
                        in2='effect1_dropShadow_1_703'
                        result='shape'
                    />
                </filter>
            </defs>
        </svg>
    )
}

export default HighSpeedIcon
