function PasswordIcon() {
    return (
        <svg
            width='12'
            height='12'
            viewBox='0 0 12 12'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M4.76486 0.0317354C4.02496 0.0762224 3.58242 0.127734 3.08369 0.228417C1.152 0.614757 0.330145 1.63095 0.0819509 3.93728C0.0210731 4.52264 0 5.01903 0 6.00947C0 6.9999 0.0210731 7.49629 0.0819509 8.08165C0.330145 10.388 1.152 11.4042 3.08369 11.7929C3.84935 11.9451 4.31296 11.9825 5.68271 11.9966C6.85578 12.0083 7.43412 11.9919 8.07802 11.9193C10.6489 11.6383 11.63 10.6573 11.904 8.08868C12.0163 7.05141 12.021 5.10801 11.918 4.03094C11.7401 2.20929 11.1992 1.18139 10.1244 0.631147C9.47352 0.296319 8.63294 0.118369 7.35217 0.0387592C7.01031 0.0176859 5.07393 0.0130033 4.76486 0.0317354ZM4.48154 4.08947C5.10671 4.25103 5.61949 4.70762 5.84427 5.30703L5.92154 5.50605L7.7666 5.51776C9.08484 5.52713 9.62806 5.53649 9.67255 5.55757C9.77557 5.60205 9.88562 5.7121 9.93011 5.81044C9.96991 5.89473 9.97459 5.97903 9.97459 6.75873C9.97459 7.72107 9.97225 7.73512 9.79898 7.8756C9.57421 8.06058 9.29557 8.02546 9.10591 7.78897C9.01226 7.6719 8.99118 7.52673 8.99118 6.97883V6.50117H8.48777H7.98436V6.77512C7.98436 7.10527 7.96094 7.20127 7.85324 7.32068C7.74787 7.43541 7.64251 7.48458 7.49265 7.48458C7.3428 7.48458 7.23743 7.43541 7.13207 7.32068C7.02436 7.20127 7.00095 7.10527 7.00095 6.77512V6.50117L6.46007 6.50585L5.92154 6.51288L5.84427 6.7119C5.66632 7.18253 5.31276 7.56887 4.86086 7.78897C4.09286 8.16126 3.21013 8.01375 2.6037 7.412C1.70224 6.5199 1.85443 5.04713 2.92213 4.34703C3.38106 4.04733 3.95237 3.95133 4.48154 4.08947Z'
                fill='white'
            />
            <path
                d='M3.67474 5.05538C3.39376 5.15138 3.20879 5.31762 3.08235 5.58455C3.01445 5.72972 3.00977 5.75547 3.00977 6.01069C3.00977 6.27527 3.01211 6.2823 3.09874 6.45791C3.28372 6.83488 3.63728 7.03625 4.0681 7.00815C4.9321 6.9543 5.32546 5.87957 4.69561 5.28484C4.50361 5.10221 4.30927 5.02026 4.04235 5.00855C3.8644 5.00152 3.80352 5.00855 3.67474 5.05538Z'
                fill='white'
            />
        </svg>
    )
}

export default PasswordIcon
